const values = {
    current_points: 'CURRENT POINTS',
    fight_number: 'Fight #',
    betting_status: 'Betting Status:',
    operating_first_typography: 'There are no ongoing event as of the moment!',
    operating_second_typography: {
        first_line: `The event usually starts at 8:00 AM`,
        second_line: `Please come back later to play `,
        third_line: `Thank you!`
    },
    internetonline: `You're back online!`,
    internetoffline: 'Your internet connection has been lost.',
    claimwinnings: 'Are you sure your customer has already claimed his / her winnings?',
    claimbuttonyes: 'YES, ALREADY CLAIMED',
    claimbuttonno: 'No, Cancel'
}

export default values