import React from 'react'
import Values from '../../utils/values'
import { Button, Modal } from 'react-bootstrap'
import warninglogo from '../../assets/images/unsuccessful.png'

export default function OperatingClose(props) {
    return (
        <>
            <div className='operating-container'>
                <div class='text-center'>
                    <img src={warninglogo} alt='Responsive image' className='rounded mx-auto d-block' width={170} height={140} />
                </div>
                <div class='text-center'>
                    <p className='operating-typography-header2'> Oops!</p>
                    <p className='operating-typography-header'>{Values.operating_first_typography}</p>
                    <p className='operating-typography-header'>{Values.operating_second_typography.first_line} <br /> {Values.operating_second_typography.second_line} <br /><br /> {Values.operating_second_typography.third_line}</p>
                </div>
            </div>
        </>
    )
}