import React from 'react'
import Header from '../../../components/AppBar/Header'
import { useSelector } from 'react-redux'
import Values from '../../../utils/values'

const Dashboard = props => {
    const playdata = useSelector(state => state.play)
    const liveStream = playdata.data.liveStream

    return (
        <div>
           <Header />
           <p>Consumer Dashboard</p>
        </div>
    )
}

export default Dashboard