import { storeSubEventId, getSessionId } from '../../utils/Auth'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getMode = async (sessionId) => {
    try {
        const token = Cookies.get('token')
        // park for the get token for authorization
        const result = await axios({
            url: process.env.REACT_APP_MEBET_API_BASE_URL + '/v1/sessions/validate',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            method: 'post',
            data: {
                sessionId: sessionId
            }
        })

        storeSubEventId(result.data.data.subEventId)

        const data = result.data.data
        return data

    } catch (err) {
        throw err
    }
}


export const getWinningsList = async (page=false) => {
    try {
        const token = Cookies.get('token')
        // park for the get token for authorization
        const result = await axios({
            url: process.env.REACT_APP_MEBET_API_ESABONG_BASE_URL + `/v1/winnings?session_id=${getSessionId()}&order=DESC`,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })

        const data = result.data.data
        return data

    } catch (err) {
        throw err
    }
}

export const claimWinnings = async (payload) => {
    try {
        const token = Cookies.get('token')
        // park for the get token for authorization
        const result = await axios({
            url: process.env.REACT_APP_MEBET_API_ESABONG_BASE_URL + '/v1/winnings/claim',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            data: {
                sessionId:payload.sessionId,
                referenceId:payload.referenceId
            }
        })

        const data = result.data.data
        return data

    } catch (err) {
        throw err
    }
}