import Cookies from 'js-cookie'

export const storeSession = (sessionId) => {
    Cookies.set('sessionId', sessionId)
}

export const storeSubEventId = (subEventId) => {
    Cookies.set('subEventId', subEventId)
}

export const getSubEventId = () => {
    return Cookies.get('subEventId')
}

export const getSessionId = () => {
    return Cookies.get('sessionId')
}

export const removeSessionId = () => {
    return Cookies.remove('sessionId')
}

export const storeToken = (accessToken) => {
    var expire = new Date(new Date().getTime() + 60 * 60 * 1000)
    Cookies.set('token', accessToken, { expires: expire })
}

export const getToken = () => {
    return Cookies.get('token')
}

export const TerminateSessions = () => {
    Cookies.remove('sessionId')
    Cookies.remove('token')
    Cookies.remove('subEventId')
}