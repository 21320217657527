import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import { FaRegEdit } from 'react-icons/fa'
import {BsPersonCircle} from 'react-icons/bs'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getSessionId, getSubEventId } from '../../../utils/Auth'
import { deleteAgentPlayer } from '../../../store/BetFields/BetFieldServices'
import {getAgentPlayers} from '../../../store/BetFields/BetFieldActions'
import EditFormModal from './EditFormModal'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const Players = ({player}) => {
    const Swal2 = withReactContent(Swal)
    const [show, setShow] = useState(false)
    const [active, setActive] = useState(false)
    const dispatch = useDispatch()
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    useEffect(() => {
        handleClose()
    }, [player])

    const data ={
        name: player.name,
        mobileNumber: player.mobileNumber,
        id: player.agentPlayerId
    }
    
    const HandleDeletePlayer = (id) => {
    
        let payload = {
            sessionId: getSessionId(),
            agentPlayerId: id
        }

        Swal2.fire({
            title: `<p class='success-message'> Are you sure you want to delete player ${player.name} ?</p>`,
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'YES REMOVE',
            customClass: {
                confirmButton: 'btn swal-btn-confirm btn-block',
                cancelButton: 'btn swal-btn-cancel'
            },
            buttonsStyling: false
        }).then((result) => {
            if(result.isConfirmed) {
            deleteAgentPlayer (payload)
            .then((res) => {
                toast.error(`Player deleted.`,
                {position: toast.POSITION.BOTTOM_CENTER, theme: "colored" })
            // Swal2.fire({
            //     html: `<p class='success-message'> Palyer successfully deleted.</p>`,
            //     target: '#custom-target',
            //     showCloseButton: true,
            //     customClass: {
            //       container: 'position-absolute'
            //     },
            //     toast: true,
            //     position: 'bottom-right',
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //     timer: 2000,
            //     closeModal: true,
            //   })
              dispatch(getAgentPlayers())
            })
            .catch((err) => {
                Swal2.fire({
                    icon: 'warning',
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 5500
                })
            })
            .finally(() => {
               
            })
            }
        })
    }
  
    return (
        <>
            <td className='ellipsis first'> 
                <div className='displayNameCont'>
                    <div className='displayNameIcon'> <BsPersonCircle className='playerIcon'/> </div>
                    <div className='displayName'><span>{player.name.toUpperCase()}</span></div>
                </div>
            </td>
            <td>{player.mobileNumber}</td>
            <td>
                <a onClick={handleShow}  className='btnIcons text-muted btn-act'>
                    <i className='material-icons' ><FaRegEdit className='actionIcon'/></i></a>
                <a onClick={() => HandleDeletePlayer(player.agentPlayerId)} 
                    className='btnIcons text-muted btn-act' data-toggle='modal'>
                        <i className='material-icons' ><FaTrash className='actionIcon'/></i></a>
            </td>

            <Modal show = {show} onHide={handleClose} centered className='player-modal'>
                <Modal.Header>
                    <Modal.Title>
                    <div className='modalTitle'>Edit Player Details</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditFormModal player = {data}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Players;