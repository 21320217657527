import api from '../../utils/Api'

export const getBettingHistory = async (sessionID) => {
    try {
        const res =  await api({
            url: `/v1/bets?session_id=${sessionID}`,
            method: 'GET'
        })
        const data = res.data
        return data

    } catch(err) {
        throw err
    }
}
