export const AGENT_MODE = 'AGENT_MODE'

export const USER_MODE = 'USER_MODE'

export const GET_MODE = 'GET_MODE'

export const GET_MODE_FAILED = 'GET_MODE_FAILED'

export const GET_MODE_SUCCESS = 'GET_MODE_SUCCESS'


export const FIGHT_DATA = 'FIGHT_DATA'

export const ARENA_DATA = 'ARENA_DATA'

export const BETTING_DATA = 'BETTING_DATA'

export const FIGHT_DATA_SUCCESS = 'FIGHT_DATA_SUCCESS'

export const ARENA_DATA_SUCCESS = 'ARENA_DATA_SUCCESS'

export const BETTING_DATA_SUCCESS = 'BETTING_DATA_SUCCESS'

export const PLAY_EXPIRED = 'PLAY_EXPIRED'

export const WINNINGS_LIST = 'WINNINGS_LIST'

export const WINNINGS_LIST_SUCCESS = 'WINNINGS_LIST_SUCCESS'

export const WINNINGS_LIST_FAILED = 'WINNINGS_LIST_FAILED'

export const SEQUENCE_DATA = 'SEQUENCE_DATA'

export const SEQUENCE_DATA_SUCCESS = 'SEQUENCE_DATA_SUCCESS'

export const BACCARAT_DATA = 'BACCARAT_DATA'

export const BACCARAT_DATA_SUCCESS = 'BACCARAT_DATA_SUCCESS'

export const SEQUENCE_DATA_QUERY = 'SEQUENCE_DATA_QUERY'

export const SEQUENCE_DATA_QUERY_SUCCESS = 'SEQUENCE_DATA_QUERY_SUCCESS'

export const BACCARAT_DATA_QUERY = 'BACCARAT_DATA_QUERY'

export const BACCARAT_DATA_QUERY_SUCCESS = 'BACCARAT_DATA_QUERY_SUCCESS'

