import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PullToRefresh from 'pulltorefreshjs'
import moment from 'moment'

import NoBetHistoryIcon from '../../../assets/images/no_bet_history.svg'
import { getBettingHistory } from '../../../store/BettingHistory/BettingHistoryActions'
import { GET_BETTING_HISTORY_SUCCESS } from '../../../store/BettingHistory/BettingHistoryType'

import { FaSpinner } from 'react-icons/fa'

export default function BettingHistoryItem(props) {
    const dispatch = useDispatch()
    const bettingHistory = useSelector(state => state.getBettingHistory)

    useEffect(() => {
        console.log('__ONLOAD')
        dispatch(getBettingHistory())
    }, [GET_BETTING_HISTORY_SUCCESS])

    useEffect(() => {
        PullToRefresh.init({
            ptrElement: 'betting-history-pull-trigger',
            onRefresh() {
                dispatch(getBettingHistory())
            }
        })

        return () => {
            PullToRefresh.destroyAll()
        }
    })

    return (
        <React.Fragment>
            { 
                bettingHistory.loading == true ?
                    <section className='betting-history-loading'>
                        <p>Loading.. <FaSpinner className='spinn' /></p>
                    </section>
                :
                bettingHistory.data != null ? 
                    bettingHistory.data.history?.length > 0 ?
                        <div className='betting-history-container'>
                            <div className='betting-history-header'>
                                <p>EVENT</p>
                            </div>
                            <div className='betting-history-body'>
                                <p>{bettingHistory.data.event}</p>
                            </div>

                            <div className='betting-history-header'>
                                <p>STATISTICS</p>
                            </div>
                            <div className='betting-history-body'>
                                <div className='betting-history-inititial-points'>
                                    <p>Wins</p>
                                    <p>{bettingHistory.data.statistics.wins}</p>
                                </div>
                                <div className='betting-history-total-wins'>
                                    <p>Losses</p>
                                    <p>{bettingHistory.data.statistics.losses}</p>
                                </div>
                                <div className='betting-history-difference'>
                                    <p>Difference (Win-Lose)</p>
                                    <p>{bettingHistory.data.statistics.difference}</p>
                                </div>
                            </div>

                            <div className='betting-history-table-container'>
                                <table className='table betting-history-table'>
                                    <thead>
                                        <tr>
                                            <th>FIGHT NUMBER</th>
                                            <th>STATUS</th>
                                            <th>POINTS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bettingHistory.data.history.map((history, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className='betting-history'>
                                                                <div className='betting-history-details'>
                                                                    <p className='betting-history-fight-number'>#{history.fightNumber} =&nbsp;
                                                                        <span className={`betting-history-bet-type ${history.bet === 'MERON' ? 'bet-meron' : 
                                                                            history.bet === 'DRAW' ? 'bet-draw' : history.bet === 'WALA' ? 'bet-wala' : 'bet-cancelled'}`}>
                                                                                { history.bet.toUpperCase() }
                                                                        </span>    
                                                                    </p>
                                                                    <p className='betting-history-date'>{moment(history.createdAt).format('MMMM DD YYYY h:mm a')}</p>
                                                                    <p className='betting-history-name'>{history.playerName}</p>
                                                                    <p className='betting-history-contact'>{history.playerMobileNumber}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='betting-history'>
                                                                <div className='betting-history-details'>
                                                                    <p className='betting-history-status'>{history.status}</p>
                                                                    <p className='betting-history-bet-amount'>BET: {(Math.round(history.betAmount * 100) / 100).toFixed(2)}</p>
                                                                    <p className='betting-history-bet-type'>on {history.bet}</p>
                                                                    <p className='betting-history-reference-number'>REF#{history.referenceId}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='betting-history'>
                                                                <div className='betting-history-details'>
                                                                    <p className='betting-history-amount-result'>
                                                                        {
                                                                            history.status.toLowerCase() === 'cancelled' || history.status.toLowerCase() === 'draw' ? '⏎' :
                                                                                history.status.toLowerCase() === 'win' ? '+' : ''
                                                                        }
                                                                        {(Math.round(history.resultAmount * 100) / 100).toFixed(2)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {
                                    bettingHistory.data.history?.length > 100 ?
                                        <section className='betting-history-pull-trigger'>
                                            <span>PULL UP TO LOAD MORE TRANSACTIONS</span>
                                        </section>
                                    : ''
                                }
                                
                            </div>
                        </div>
                    :
                    <section className='no-betting-history-section'>
                        <img src={NoBetHistoryIcon} alt='icon' className='no-betting-history-icon' />
                        <div className='no-betting-history-message'>
                            <p>You don't have any transaction yet.</p>
                        </div>
                    </section>
                :
                    null
            }
        </React.Fragment>
    )
}