import React, { useEffect } from 'react'
import Values from '../../utils/values'
import { toast } from 'react-toastify'

export default function InternetConnectionAlert(props) {
    navigator.onLine
        ? console.log(true)
        : console.log(false)


    useEffect(() => {
        window.addEventListener('online', () => {
            toast.success(Values.internetonline, {
                toastId: 'online1',
                position: 'bottom-center', autoClose: 3000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: false, theme: 'colored'
            })
        })
        window.addEventListener('offline', () => {
            toast.error(Values.internetoffline, {
                toastId: 'offline1',
                position: 'bottom-center', autoClose: 3000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: false, theme: 'colored'
            })
        })
    }, [])


    return <div></div>
}