import React from 'react'
import pitmastersLogo from '../../assets/images/pitmasters_logo.png'
import * as AiIcons from 'react-icons/ai'
import { useState } from 'react'
import './SideDrawerHeader.css'
import { Link } from 'react-router-dom'

export default function SideDrawerHeader(props) {

    return (
        <div className='navbar navbar-dark bg-dark'>
            <div className='container-fluid'>
                <AiIcons.AiOutlineClose className='closeBtn' onClick={props.clickHandler}/>
                <div id='menuTitle'><h3>Menu</h3></div>

                <img src={pitmastersLogo} alt='logo' className='pitLogo' onClick={props.clickHandler}/>
            </div>
        </div>
    )
}