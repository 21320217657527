import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { winningsList } from '../../../store/Play/PlayActions'
import { FormControl, InputGroup } from 'react-bootstrap'
import Items from './Items'

export default function WinningsListItems(props) {

    const dispatch = useDispatch()
    const dataList = useSelector(state => state.play.winningslist)
    const inputEl = useRef("")
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResult, setSearchResult] = useState([])

    const [isFetching, setIsFetching] = useState(false)
    const [isIndex, setIsIndex] = useState(9)


    useEffect(() => {
        dispatch(winningsList())
        window.addEventListener('scroll', handleScroll)
    }, [])

    const getSearchTerm = () => {

        if (inputEl.current.value !== "") {
            setSearchTerm(inputEl.current.value)
            const newDataList = dataList.filter((data) => {
                if (data.player.mobileNumber.toLowerCase().includes(inputEl.current.value.toLowerCase())
                    || data.player.name.toLowerCase().includes(inputEl.current.value.toLowerCase())
                    || data.referenceId.toLowerCase().includes(inputEl.current.value.toLowerCase())) {
                    return data
                } else {
                    return null
                }

            })
            setSearchResult(newDataList)
        }
        else {
            setSearchResult(dataList)
        }
    }

    const handleScroll = () => {
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight ||
            isFetching
        )
            return
        setIsFetching(true)
    }

    useEffect(() => {
        if (!isFetching) return
        setIsIndex(isIndex + 10)

        if (isIndex > dataList.length) {
            setIsFetching(true)
        } else {
            setIsFetching(false)
        }

    }, [isFetching])

    return (
        <>

            <div className='winning-list-container'>
                <center><p className='winning-list-Headers'>Winnings List</p></center>
                <InputGroup className="mb-3">
                    <FormControl className='winning-input-search-bar' placeholder='Search Player, Number, or Reference #' ref={inputEl} onChange={getSearchTerm} />
                    <InputGroup.Text className='winning-input-search-bar'><i className="fa fa-search"></i> </InputGroup.Text>
                </InputGroup>
                {
                    dataList != null ? 
                        searchTerm.length < 1 ?
                                <table className="table">
                                    <tbody>
                                        {
                                            dataList.map((data, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {i <= isIndex ? <Items key={data} data={data} /> : <></>}
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> 
                        : searchResult.length <= 0 ? <center><p className='noMatches'>Oops! No Matches Found</p></center> :
                        
                                <table className="table">
                                    <tbody>
                                        {
                                            searchResult.map((data, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        {i <= isIndex ? <Items key={data} data={data} /> : <></>}
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> 
                    : <center><h4 className='noMatches'>No winnings List</h4></center>
                }
            </div>

        </>
    )
}