import * as types from './PlayType'

const INITIAL_STATE = {
  isLoading: false,
  error: {
    flag: false,
    msg: null
  },
  data: null
}

const PlayReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MODE:
      return {
        ...state,
        isLoading: true
      }
    case types.GET_MODE_SUCCESS:
      let details = action.payload
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        fightdata: {
          subEventId: details.subEventId,
          eventId: details.eventId,
          openBet: details.openBet,
          fightNumber: details.fightNumber,
          status: details.status,
          winner: details.winner,
          waitingDecision: details.waitingDecision,
          meronEqualPoint: details.meronEqualPoint,
          walaEqualPoint: details.walaEqualPoint,
        },
        bettingdata: {
          meronOdds: details.meronOdds,
          walaOdds: details.walaOdds,
          walaBets: details.walaBets,
          drawBets: details.drawBets,
          meronBets: details.meronBets,
          betCount: '',
        },
        arenadata: {
          event: details.eventTitle,
          announcement: details.eventDescription
        },
      }
    case types.AGENT_MODE:
      return {
        ...state,
        ...action.payload
      }
    case types.USER_MODE:
      return {
        ...state,
        ...action.payload
      }
    case types.FIGHT_DATA_SUCCESS:
      return {
        isLoading: false,
        ...state,
        fightdata: action.payload
      }
    case types.ARENA_DATA_SUCCESS:
      return {
        isLoading: false,
        ...state,
        arenadata: action.payload
      }
    case types.BETTING_DATA_SUCCESS:
      return {
        isLoading: false,
        ...state,
        bettingdata: action.payload
      }
    case types.PLAY_EXPIRED:
      return {
        isLoading: true,
        data: null
      }
    case types.WINNINGS_LIST_SUCCESS:
      return {
        isLoading: false,
        ...state,
        winningslist: action.payload
      }
    case types.WINNINGS_LIST_FAILED:
      return {
        isLoading: false,
         ...state,
        winningslist: null
      }
    case types.SEQUENCE_DATA_SUCCESS:
    return {
      isLoading: false,
      ...state,
      sequencedata: action.payload
    }
    case types.BACCARAT_DATA_SUCCESS:
    return {
      isLoading: false,
      ...state,
      baccaratdata: action.payload
    }
    case types.SEQUENCE_DATA_QUERY_SUCCESS:
    return {
      isLoading: false,
      ...state,
      sequencedataquery: action.payload
    }
    case types.BACCARAT_DATA_QUERY_SUCCESS:
    return {
      isLoading: false,
      ...state,
      baccaratdataquery: action.payload
    }
    default:
      return state
  }
}

export default PlayReducer