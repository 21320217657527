import { getWalletUpdate } from './HeaderServices'
import { all, takeLatest, put } from '@redux-saga/core/effects'
import { GET_WALLET_UPDATE } from './HeaderType'
import { getSessionId } from '../../utils/Auth'
import { call } from 'redux-saga/effects'

import { getWalletUpdateSuccess, updateWalletUpdateFail } from './HeaderAction'

function* getWalletUpdateRequest() {
    try {
        const response = yield call(getWalletUpdate, getSessionId())
        yield put(getWalletUpdateSuccess(response.data))
    } catch(err) {
        yield put(updateWalletUpdateFail(err))
    }
}


export default function* watchHeaderRequests() {
    yield all([
        takeLatest(GET_WALLET_UPDATE, getWalletUpdateRequest)
    ])
}