export const selectWala = () => {
    return {
        type: 'WALA_SELECTED'
    }
}

export const selectDraw = () => {
    return {
        type: 'DRAW_SELECTED'
    }
}

export const selectMeron = () => {
    return {
        type: 'MERON_SELECTED'
    }
}

export const disabledBetTypeButton = (props) => {
    return {
        type: props
    }
}

export const activeButton = (props) => {
    return {
        type: props
    }
}