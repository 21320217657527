import {
    FIGHT_DATA,
    ARENA_DATA,
    BETTING_DATA,
    PLAY_EXPIRED,
    GET_MODE,
    WINNINGS_LIST,
    SEQUENCE_DATA,
    BACCARAT_DATA,
    SEQUENCE_DATA_QUERY,
    BACCARAT_DATA_QUERY,

} from './PlayType'

export const initiateGet = (payload) => {
    return {
        type: GET_MODE,
        payload
    }
}

export const webSocketFight = (payload) => {
    return {
        type: FIGHT_DATA,
        payload
    }
}

export const webSocketArena = (payload) => {
    return {
        type: ARENA_DATA,
        payload
    }
}

export const webSocketBetting = (payload) => {
    return {
        type: BETTING_DATA,
        payload
    }
}

export const playExpired = () => {
    return {
        type: PLAY_EXPIRED
    }
}


export const winningsList = () => {
    return {
        type: WINNINGS_LIST
    }
}

export const webSocketSequence = (payload) => {
    return {
        type: SEQUENCE_DATA,
        payload
    }
}

export const webSocketBaccarat = (payload) => {
    return {
        type: BACCARAT_DATA,
        payload
    }
}

export const querySequence = (payload) => {
    return {
        type: SEQUENCE_DATA_QUERY,
        payload
    }
}

export const queryBaccarat = (payload) => {
    return {
        type: BACCARAT_DATA_QUERY,
        payload
    }
}
