import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { disabledBetTypeButton } from '../../store/BetTypes/BetTypeActions'
import { FaChevronLeft } from 'react-icons/fa'

export default function FightDetailsHeader(props) {
    const dispatch = useDispatch()

    const playdatafight = useSelector(state => state.play.fightdata)
    const playdataarena = useSelector(state => state.play.arenadata)

    const [video, setVideo] = useState('')

    useEffect(() => {
        setVideo('<style> .iframe-container { height:400px; } #betting-dashboard .iframe-container { padding-top: 56.25%; height: 0; position: relative; } #betting-dashboard .iframe-container .wpcstream-iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:0px #ffffff none; } </style> <div class="iframe-container"> <iframe class="wpcstream-iframe" src="https://dev.wpcstream-003.com" name="stream1" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="100%" width="100%"> </iframe> </div>')
    })
    
    useEffect(() => {
        // toggle disabled button 
        if (playdatafight.openBet.toLowerCase() === 'no' || 
            playdatafight.openBet.toLowerCase() === 'close' || playdatafight.openBet.toLowerCase() === 'closed') {
                dispatch(disabledBetTypeButton('DISABLED_BUTTON'))
        } else {
            dispatch(disabledBetTypeButton('ACTIVE_BUTTON'))
        }
    }, [playdatafight])

    return (
        <>
            <div className={`fight-details-video-container ${playdatafight.winner == null || playdatafight.status.toLowerCase() === 'on-going' ? 'flex' : ''}`}>
                {
                    playdatafight.winner == null || playdatafight.status.toLowerCase() === 'on-going' ? 
                        <div className='live-streaming-video-container'>
                            <div dangerouslySetInnerHTML={{__html: video}}></div>
                        </div>
                    : null
                }
                <div className={`fight-details-container 
                    ${playdatafight.winner !== null && playdatafight.winner.toLowerCase() === 'draw' ? 'green-bg' :
                        playdatafight.winner !== null && playdatafight.winner.toLowerCase() === 'wala' ? 'blue-bg' : 
                        playdatafight.status.toLowerCase() === 'canceled' ? 'cancel-bg' : 'red-bg'}`}>
                    <div className='container-fluid'>
                        {
                            playdatafight.winner == null || playdatafight.winner === '' ?
                                <>
                                    <h3 className='fight-details-number'>CURRENT FIGHT: #{playdatafight.fightNumber}</h3>
                                    <div className='fight-details-status-container'>
                                        <p className='fight-details-betting-status'>Betting Status: {
                                            playdatafight.openBet !== undefined && playdatafight.openBet.toLowerCase() === 'yes' || playdatafight.openBet.toLowerCase() === 'open' ?
                                                <span className='badge badge-green'>OPEN</span> :
                                                <span className='badge badge-red'>CLOSED</span>
                                        }
                                        </p>
                                    </div>
                                </> :
                                playdatafight.winner !== null  && playdatafight.winner.toLowerCase() === 'meron' ?
                                    <>
                                        <p className='fight-details-number-winner'>FIGHT: #{playdatafight.fightNumber}</p>
                                        <p className='fight-details-winner'>MERON WINS</p>
                                    </> :
                                    playdatafight.winner !== null && playdatafight.winner.toLowerCase() === 'wala' ?
                                        <>
                                            <p className='fight-details-number-winner'>FIGHT: #{playdatafight.fightNumber}</p>
                                            <p className='fight-details-winner'>WALA WINS</p>
                                        </> :
                                        playdatafight.winner !== null && playdatafight.winner.toLowerCase() === 'draw' ?
                                            <>
                                                <p className='fight-details-number-winner'>FIGHT: #{playdatafight.fightNumber}</p>
                                                <p className='fight-details-winner'>DRAW WINS</p>
                                            </> :
                                            playdatafight.status.toLowerCase() === 'cancelled' ?
                                                <>
                                                    <p className='fight-details-number-winner'>FIGHT: #{playdatafight.fightNumber}</p>
                                                    <p className='fight-details-winner'>CANCELLED</p>
                                                </>
                                                : null
                        }
                    </div>
                </div>
            </div>
            <div className='fight-details-announcement-banner'>
                <marquee scrolldelay='100'>
                    {playdataarena.event} {playdataarena.announcement}
                </marquee>
                <span>
                    <FaChevronLeft />
                </span>
            </div>
        </>
        
    )
}