import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaYoutube } from 'react-icons/fa'
import moment from 'moment'

export default function VideoAndFightDetails() {
    const play = useSelector(state => state.play)
    const [video, setVideo] = useState('')
    
    useEffect(() => {
        setVideo('<style> .iframe-container { height:400px; } #betting-dashboard .iframe-container { padding-top: 56.25%; height: 0; position: relative; } #betting-dashboard .iframe-container .wpcstream-iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; border:0px #ffffff none; } </style> <div class="iframe-container"> <iframe class="wpcstream-iframe" src="https://dev.wpcstream-003.com" name="stream1" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="100%" width="100%"> </iframe> </div>')
    })
    return (
        <div className='live-streaming-container'>
            <div className='live-streaming-video-container'>
                <div dangerouslySetInnerHTML={{__html: video}}></div>
            </div>
            <div className='live-streaming-flash-details-banner'>
                <div className='live-streaming-icon'>
                    <FaYoutube className='video-icon'/>
                </div>
                <div className='live-streaming-flash-details'>
                    <marquee scrolldelay="100">
                        {
                            play.fightdata.winner != null ?
                                play.fightdata.winner + ' WINS'
                                :
                                play.arenadata.announcement
                        }
                    </marquee>
                </div>
            </div>
            <div className='live-streaming-events-container'>
                <div className='live-streaming-event-title'>
                    <span className='live-streaming-label'>Ongoing Events: </span><span>{moment(play.data.eventDate).format('LL')}</span>
                </div>
                <div className='live-streaming-event-description'>
                    <p>{ play.arenadata.event }</p>
                </div>
            </div>
        </div>
    )
}