const initialState = 'MERON_SELECTED'

export const betTypeButtonSelect = (state = initialState, action) => {
    switch(action.type) {
        case 'DRAW_SELECTED':
            return state = action.type
        case 'WALA_SELECTED':
            return state = action.type
        case 'MERON_SELECTED':
            return state = action.type
        default:
            return state
    }
}

export const betTypeDisableButton = (state = true, action) => {
    switch(action.type) {
        case 'DISABLED_BUTTON':
            return state = true
        case 'ACTIVE_BUTTON':
            return state = false
        default:
            return state
    }
}