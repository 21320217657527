import { all } from 'redux-saga/effects'
import watchAuthRequests from './Auth/AuthSaga'
import watchPlayRequests from './Play/PlaySaga'
import watchBetFieldRequest from './BetFields/BetFieldSaga'
import watchHeaderRequests from './AppBar/HeaderSaga'
import watchBettingHistoryRequest from './BettingHistory/BettingHistorySaga'

export default function* rootSaga() {
    yield all([
        watchAuthRequests(),
        watchPlayRequests(),
        watchBetFieldRequest(),
        watchHeaderRequests(),
        watchBettingHistoryRequest()
    ])
}