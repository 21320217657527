import axios from 'axios'
import axiosRetry from 'axios-retry'
import Cookies from 'js-cookie'
import history from './history'

let instance = axios.create({
    baseURL: process.env.REACT_APP_MEBET_API_ESABONG_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

instance.interceptors.request.use(config => {
    const token = Cookies.get('token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    config.headers['Cache-Control'] = 'no-cache'
    return config
})

instance.interceptors.response.use(null, (error) => {
    if (error.response.status === 401) {

        history.push('/400')
    }
    if (error.response.status === 400) {
        // expired session
        if(error.response.data.message.search('0016')){
            // history.push('/400')
        }
       
    }
    if (422 === error.response.status) {
        console.log(error)
    }
    return Promise.reject(error)
})


axiosRetry(instance, {
    retries: 2,
    retryDelay: (retryCount) => {
        return retryCount * 2000
    },
    retryCondition: (error) => {
        const codes = [200]
        return !codes.includes(error.response.status)
    },
})

export default instance