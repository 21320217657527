import * as types from './AuthTypes'

const INITIAL_STATE = {
  isLoading: false,
  error: {
    flag: false,
    msg: null
  },
  data:null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.START_AUTH:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_AUTH:
      return {
        isLoading: false,
        data:action.payload
      }
    case types.FAILED_AUTH:
      return {
        ...state,
        error: {
          flag: true,
          msg: 'Unauthorized'
        },
        isLoading: false,
        data:null
      }
    case types.DESTROY_AUTH:
      return {
        ...state,
        error: {
          flag: true,
          msg: 'Error'
        },
        isLoading: false,
        data:null
      }
    default:
      return state
  }
}