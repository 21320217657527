import { GET_WALLET_UPDATE, GET_WALLET_SUCCESS, GET_WALLET_FAIL } from './HeaderType'

export const getWalletUpdate = () => {
    return {
        type: GET_WALLET_UPDATE
    }
}

export const getWalletUpdateSuccess = (data) => {
    return {
        type: GET_WALLET_SUCCESS,
        payload: data
    }
}

export const updateWalletUpdateFail = (error) => {
    return {
        type: GET_WALLET_FAIL,
        payload: error
    }
}