import { getAgentPlayers } from './BetFieldServices'
import { all, put, takeLatest } from '@redux-saga/core/effects'
import { call } from 'redux-saga/effects'
import { GET_AGENT_PLAYERS } from './BetFieldTypes'
import { getSessionId } from '../../utils/Auth'
import { getAgenPlayersSuccess, getAgentPlayersFailed } from './BetFieldActions'

function* getAgentPlayersRequest() {
    try {
        const response = yield call(getAgentPlayers, getSessionId())
        yield put(getAgenPlayersSuccess(response.data))
    } catch(err) {
        yield put(getAgentPlayersFailed(err))
    }
}

export default function* watchBetFieldRequest() {
    yield all([
        takeLatest(GET_AGENT_PLAYERS, getAgentPlayersRequest)
    ])
}