import { Form, Button } from 'react-bootstrap'
import {useContext, useState} from 'react'
import AddPlayerButton from './AddPlayerButton'


const AddForm = () =>{

    const  [name,setName] = useState('')
    const  [number,setNumber] = useState('')
    const  [formErrors, setFormErrors] = useState({})
    
    let props ={
        name:name,
        mobileNumber:number
    }
    const handleClick = (data) => {
        setFormErrors(data)
    }   

     return (
        <Form>
            <Form.Group>
                <Form.Control
                    className='formInput'
                    type='text'
                    placeholder='Name *'
                    name='name'
                    onChange={event => setName(event.target.value)}
                    required
                />
            </Form.Group>
            <p>{formErrors.name}</p>
            <Form.Group>
                <Form.Control
                    className='formInput'
                    type='tel'
                    placeholder='Phone *'
                    name='phone'
                    onChange={event => setNumber(event.target.value)}
                />
            </Form.Group>
            <p>{formErrors.mobileNumber}</p>
            <AddPlayerButton values={props} parentCallback = {handleClick}/>
        </Form>

     )
}

export default AddForm;