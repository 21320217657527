import {
    START_AUTH,
    FAILED_AUTH,
    DESTROY_AUTH
} from './AuthTypes'
import { TerminateSessions } from '../../utils/Auth'

export const initiateAuth = (payload) => {
    return {
        type: START_AUTH,
        payload
    }
}

export const failedAuth = (payload) => {
    return {
        type: FAILED_AUTH,
        payload: null
    }
}

export const destroyAuth = (payload) => {
    TerminateSessions()
    return {
        type: DESTROY_AUTH,
        payload: null
    }
}