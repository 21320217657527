import React, { useState, useEffect } from 'react'
import { editAgentPlayer, createAgentPlayer } from '../../../store/BetFields/BetFieldServices'
import { getSessionId } from '../../../utils/Auth'
import {getAgentPlayers} from '../../../store/BetFields/BetFieldActions'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button } from "react-bootstrap"
import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

export default function AddPlayerButton(props) {
    const Swal2 = withReactContent(Swal)
    const [isLoading, setIsLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const notify = () => toast("Wow so easy!");
    const dispatch = useDispatch()

    const handleSubmit = () => {
        setFormErrors(validate(props.values))
        setIsSubmit(true)
        console.log(formErrors)
    }

    useEffect(() => {
        
        if (Object.keys(formErrors).length === 0 && isSubmit){
            // props.values.id?
            //    handleEditAgentPlayer: handleCreateAgentPlayer
            if(props.values.id)
                handleEditAgentPlayer()
            else
                handleCreateAgentPlayer()
        }
        else{
            props.parentCallback(formErrors)
        }
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        const regex = /^(\+639)\d{9}$/;

        if(!values.name){
            errors.name  = 'Player\'s name is required!'
        }
        if(!values.mobileNumber){
            errors.mobileNumber  = 'Player\'s mobile number is required!'
        } else if (!regex.test(values.mobileNumber)){
            errors.mobileNumber  = 'Enter mobile number in format +639XXXXXXXXXX.'
        }

        return errors
    } 

    const handleCreateAgentPlayer = () => {
        setIsLoading(true)
        props.parentCallback ("test");
        
        let payload = {
            sessionId: getSessionId(),
            name: props.values.name,
            mobileNumber: props.values.mobileNumber

        }
        createAgentPlayer (payload)
        .then((res) => {
            toast.success(`You added ${res.data.name}`,
            {position: toast.POSITION.BOTTOM_CENTER, theme: "colored"})
            // Swal2.fire({
            //     html: `<p class='success-message'> You added ${res.data.name}</p>`,
            //     target: '#custom-target',
            //     showCloseButton: true,
            //     customClass: {
            //       container: 'position-absolute'
            //     },
            //     toast: true,
            //     position: 'bottom-right',
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //     timer: 2000,
            //     closeModal: true,
            //   })
              dispatch(getAgentPlayers())
        })
        .catch((err) => {
            Swal2.fire({
                icon: 'warning',
                text: err.response.data.message,
                showConfirmButton: false,
                timer: 5500
            })
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const handleEditAgentPlayer = () => {
        setIsLoading(true)
        
        let payload = {
            sessionId: getSessionId(),
            agentPlayerId: props.values.id,
            name: props.values.name,
            mobileNumber: props.values.mobileNumber

        }
        console.log(payload)
        editAgentPlayer (payload)
        .then((res) => {
            toast.success(`You successfully updated ${res.data.name} info`,
            {position: toast.POSITION.BOTTOM_CENTER, theme: "colored" })
            // Swal2.fire({
            //     html: `<p class='success-message'> You Edited ${res.data.name}</p>`,
            //     target: '#custom-target',
            //     showCloseButton: true,
            //     customClass: {
            //     container: 'position-absolute'
            //     },
            //     toast: true,
            //     position: 'bottom-right',
            //     showCancelButton: false,
            //     showConfirmButton: false,
            //     timer: 2000,
            //     closeModal: true,
            // })
            dispatch(getAgentPlayers())
        })
        .catch((err) => {
            Swal2.fire({
                icon: 'warning',
                text: err.response.data.message,
                showConfirmButton: false,
                timer: 5500
            })
        })
        .finally(() => {
            // reset state 
            // props.tempFunc(true)
            setIsLoading(false)
        })
        
    }

    return (
        <div className='addPlayerBtnCont'>
               <Button className='saveBtn btn-block' variant='danger' onClick={handleSubmit}>SAVE</Button>
               <Button className='cancelBtn btn-block' variant='secondary'>CANCEL</Button>
        </div>
    )
}