import processActions from '../../utils/ProcessAction'
import { getMode as InitiateService, getWinningsList } from './PlayServices'
import { all, takeEvery, select} from '@redux-saga/core/effects'
import * as selectors from '../selectors'
import { put } from 'redux-saga/effects'
import {  GET_MODE,GET_MODE_SUCCESS,GET_MODE_FAILED,
           FIGHT_DATA_SUCCESS,FIGHT_DATA,
           ARENA_DATA,ARENA_DATA_SUCCESS,
           BETTING_DATA,BETTING_DATA_SUCCESS,
           WINNINGS_LIST,
           WINNINGS_LIST_SUCCESS , 
           SEQUENCE_DATA,
           SEQUENCE_DATA_SUCCESS,
           BACCARAT_DATA,
           BACCARAT_DATA_SUCCESS,
           SEQUENCE_DATA_QUERY,
           SEQUENCE_DATA_QUERY_SUCCESS,
           BACCARAT_DATA_QUERY,
           BACCARAT_DATA_QUERY_SUCCESS,
           WINNINGS_LIST_FAILED } from '../Play/PlayType'
import history from '../../utils/history'
import { storeSubEventId } from '../../utils/Auth'

function* getMode({ payload }) {
    yield processActions({
        params: payload.sessionId,
        service: InitiateService,
        success: GET_MODE_SUCCESS,
        failed: GET_MODE_FAILED,
    })

    const data = yield (select(selectors.PlayMode))
    if (data) {
  
        history.push('/dashboard')
        if (process.env.REACT_APP_ENV === 'local') {
            return
        } else {
            window.location.reload(true)
        }
        
    }
}

function getModeFailed() {
    history.push('/400')
}

function* pushFightData({ payload }) {
    storeSubEventId(payload.subEventId)
    yield put({
        type: FIGHT_DATA_SUCCESS,
        payload,
    })
}
function* pushArenaData({ payload }) {
    yield put({
        type: ARENA_DATA_SUCCESS,
        payload,
    })
}

function* pushBettingData({ payload }) {
    yield put({
        type: BETTING_DATA_SUCCESS,
        payload,
    })
}

function* winnigsListData(){
    
    yield processActions({
        service: getWinningsList,
        success: WINNINGS_LIST_SUCCESS,
        failed: WINNINGS_LIST_FAILED,
    })
}

function* pushSequenceData({ payload }) {
    yield put({
        type: SEQUENCE_DATA_SUCCESS,
        payload,
    })
}

function* pushBaccaratData({ payload }) {
    yield put({
        type: BACCARAT_DATA_SUCCESS,
        payload,
    })
}

function* pushSequenceQueryData({ payload }) {
    yield put({
        type: SEQUENCE_DATA_QUERY_SUCCESS,
        payload,
    })
}

function* pushBaccaratQueryData({ payload }) {
    yield put({
        type: BACCARAT_DATA_QUERY_SUCCESS,
        payload,
    })
}

export default function* watchPlayRequests() {
    yield all([
        takeEvery(GET_MODE, getMode),      
        takeEvery(FIGHT_DATA,pushFightData),
        takeEvery(ARENA_DATA,pushArenaData),
        takeEvery(BETTING_DATA,pushBettingData),
        takeEvery(GET_MODE_FAILED, getModeFailed),
        takeEvery(WINNINGS_LIST,winnigsListData),
        takeEvery(SEQUENCE_DATA, pushSequenceData),
        takeEvery(BACCARAT_DATA, pushBaccaratData),
        takeEvery(SEQUENCE_DATA_QUERY, pushSequenceQueryData),
        takeEvery(BACCARAT_DATA_QUERY, pushBaccaratQueryData),
    ])
}