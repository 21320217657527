import { storeToken } from '../../utils/Auth'
import axios from 'axios'

export const login = async (sessionId) => {
    try {
        // park for the get token for authorization

        const result = await axios({
            url: process.env.REACT_APP_MEBET_API_BASE_URL + '/v1/partners/oauth/token',
            method: 'post',
            headers: {
                Authorization: 'Basic ' + process.env.REACT_APP_BASIC
            },
            data: {
                grant_type: process.env.REACT_APP_GRANT_ACCESS,
                scope: process.env.REACT_APP_SCOPE,
                merchant_id: process.env.REACT_APP_MERCHANT_ID
            }
        })

        const { access_token } = result.data
        storeToken(access_token)

        return access_token
    } catch (err) {
        throw err
    }
}