import React, { useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_WALLET_BALANCE_REALTIME, GET_UPDATE_EVENT, GET_FIGHT_UPDATE, GET_SEQUENCE_DETAILS } from '../../../graphql/queries'
import { getSessionId } from '../../../utils/Auth'
import { useSelector, useDispatch } from 'react-redux'

export default function TrendsBody() {
    const [isShown, setIsShown] = useState(true)
    const playdata = useSelector(state => state.play)
    const sequenceTotal = playdata.sequencedata ? playdata.sequencedata : playdata.sequencedataquery
    const baccaratTotal = playdata.baccaratdata ? playdata.baccaratdata : playdata.baccaratdataquery
    const sequenceData = playdata.sequencedata? playdata.sequencedata.data : playdata.sequencedataquery.data
    const baccaratData = playdata.baccaratdata ? playdata.baccaratdata.data : playdata.baccaratdataquery.data

    const walletOptions = {
        variables: { sessionId: getSessionId() },
    }

    const options = {
        variables: {
            gameType: 'ESABONG',
        }
    }

    const getWalletUpdates = useSubscription(
        GET_WALLET_BALANCE_REALTIME,
        walletOptions
    )

    const getFightUpdate = useSubscription(
        GET_FIGHT_UPDATE,
        options
    )

    const array_chunks = (array, chunks) => {
        let result = [];
        let n = array.length;
        for (let i = 0; i < n; i += chunks) {
            result = [...result, array.slice(i, i + chunks)];
        }
        return result;
    }

    const process_baccarat = (array) => {
        
        let result = [];
        let insideArray = [];
        let fightResult = '';
        let arrayCount = 0;
        for (let i = 0; i < array.length; i++) {
            if(fightResult === '') fightResult = array[i].result 
            if (array[i].result === fightResult || array[i].result ==='CANCELLED'){
                insideArray.push(array[i])
            } 
            else{
                result.push(insideArray)
                insideArray = []
                fightResult = array[i].result
                insideArray.push(array[i])
            }
        }
        return result;
    }

    const toggleShow = () => {
        setIsShown(true)
    }

    const toggleHide = () => {
        setIsShown(false)
    }

    let sequence_columns = array_chunks(JSON.parse(sequenceData), 6);
    // console.log(JSON.stringify(columns))

    let baccarat_columns = process_baccarat(JSON.parse(baccaratData));
     console.log(baccarat_columns)

    return (

        <div className='trendsBody'>
            <div className='trendsHeader'>
                <div className='trendsViewTitle'>Trends View</div>
                <div className='toggleButtons'>
                    <button className={ isShown ? 'sequenceBtn' : 'btnDisabled'} onClick={toggleShow}> Sequence</button>
                    <button className={ isShown ? 'btnDisabled' : 'baccaratBtn'} onClick={toggleHide}> Baccarat</button>
                </div>
            </div>
            <div className='totalpoints'>
                <table>
                    <tr>
                        <td>

                            <div className='dotMeron'><div> {sequenceTotal.totalMeron} </div></div>
                            <div className='cirDescMeron'>Meron</div>
                        </td>
                        <td>
                            <div className='dotWala'><div> {sequenceTotal.totalWala} </div></div>
                            <div className='cirDescWala'>Wala</div>
                        </td>
                        <td>

                            <div className='dotDraw'><div>{sequenceTotal.totalDraw}  </div></div>
                            <div className='cirDescDraw'>Draw</div>
                        </td>
                        <td>

                            <div className='dotCancelled'><div>{sequenceTotal.totalCancelled}  </div></div>
                            <div className='cirDescCancelled'>Cancelled</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div className={ isShown? 'sequenceContainer' : 'hidden'}>
                {sequence_columns.map((item, index) => {
                    return (
                        <div className='sequenceBody'>
                            <div className='test'>
                                {
                                    item.map((items) => {
                                        return <div className={ items.winner ==='MERON'? 'dotMeron':
                                            items.winner === 'WALA'? 'dotWala':
                                            items.winner === 'DRAW' ? 'dotDraw':
                                            'dotCancelled'}>{items.fightNumber}</div>;
                                    })
                                }
                      </div></div>
                        
                    )
                })}
                <br></br>
                
            </div>
            <div className={ isShown? 'hidden': 'baccaratContainer'}>
                {baccarat_columns.map((item, index) => {
                return (
                    <div className='baccaratBody'>
                        <div className='test'>
                            {item.map(function (items) {
                                return <div className={items.result === 'MERON' ? 'doughnutMeron' :
                                    items.result === 'WALA' ? 'doughnutWala' :
                                        items.result === 'DRAW' ? 'doughnutDraw' :
                                            'doughnutCancelled'}></div>;
                            })}
                        </div>
                    </div>

                )
            })}
            </div>
            



        </div>
    )
}