import {
    GET_BETTING_HISTORY,
    GET_BETTING_HISTORY_SUCCESS,
    GET_BETTING_HISTORY_FAILED
} from './BettingHistoryType'

// START GETTING BET HISTORY
export const getBettingHistory = () => {
    return {
        type: GET_BETTING_HISTORY
    }
}

export const getBettingHistorySuccess = (payload) => {
    return {
        type: GET_BETTING_HISTORY_SUCCESS,
        payload
    }
}

export const getBettingHistoryFailed = (payload) => {
    return {
        type: GET_BETTING_HISTORY_FAILED,
        payload
    }
}
// END GETTING BET HISTORY