import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function GuestRoute({ component: Component, restricted, ...rest }) {
    const play = useSelector(state => state.play)
    return (
        <Route {...rest} render={props => (
            play.data ?
                <Component {...props} /> 
                : <Redirect to='/400' />
        )
        } />
    )
}

export default GuestRoute