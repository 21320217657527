import React, { useEffect } from 'react'
import pitmastersLogo from '../../assets/images/pitmasters_logo.png'
import { getWalletUpdate } from '../../store/AppBar/HeaderAction'
import pointsBalanceLogo from '../../assets/images/points_balance.svg'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { useDispatch, useSelector } from 'react-redux'
import { FaChevronLeft, FaSearch } from 'react-icons/fa'

export default function Header(props) {
    const path = window.location.pathname
    const playdata = useSelector(state => state.play)
    const walletBalanceUpdate = useSelector(state => state.getWalletUpdate.data)
    const dispatch = useDispatch()

    useEffect(() => {
        if(props.walletSubscription !== undefined) {
            if (props.walletSubscription.onUpdateWalletBalance.updateOnWallet.toLowerCase() === 'true') {
                dispatch(getWalletUpdate())
            }
        }
    }, [props.walletSubscription])

    return (
        <div className='navbar navbar-dark bg-dark'>
            <div className='container-fluid navbar-icons'>
                <div className='navbar-left-side'>
                    <DrawerToggleButton click={props.drawerClickHandler}/>

                    <a className='navbar-brand' href='/dashboard'>
                        <img src={pitmastersLogo} alt='logo' className='logo'/>
                    </a>
                </div>
                            
                <div className='navbar-user-points-container'>
                    <div className='navbar-current-points-container' id='pointsHolder'>
                        <span className='navbar-text'>
                            <p className='navbar-current-points-value'>{ props.walletSubscription !== undefined ? walletBalanceUpdate.balance : playdata.data.balance}</p>
                            <p className='navbar-current-points-label'>CURRENT POINTS</p>
                        </span>
                    </div>
                    <img src={pointsBalanceLogo} alt='icon_btn' className='navbar-points-icon' />
                </div>
                
            </div>
        </div>
    )
}