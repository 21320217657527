import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectMeron, selectDraw, selectWala } from '../store/BetTypes/BetTypeActions'
import { addPlayer } from '../store/BetFields/BetFieldActions'
import { FaUserPlus } from 'react-icons/fa'

export default function BetTypesComponent(props) {
    const dispatch = useDispatch()
    const playerList = useSelector(state => state.getAgentPlayers.data)
    const disabledButton = useSelector(state => state.betTypeDisableButton)
    const playdatabetting = useSelector(state => state.play.bettingdata)
    const playdataarena = useSelector(state => state.play.arenadata)
    const playerInteraction = useSelector(state => state.playerInteraction)
    
    const [playerCounter, setPlayerCounter] = useState(Number)

    const handleAddPlayer = () => {
        if (playerCounter <= 0) {
            return
        } else {
            dispatch(addPlayer())
            setPlayerCounter(playerCounter - 1)
        }
        
    }

    useEffect(() => {
        if (playerInteraction.data === 'REMOVE_PLAYER') {
            setPlayerCounter(playerCounter+1)
        }
    }, [playerInteraction])

    useEffect(() => {
        if (playerList.length >= 4) {
            setPlayerCounter(Math.floor(playerList.length - 4))
        } else {
            setPlayerCounter(0)
        }
    }, [playerList])

    return (
        <React.Fragment>
            <div className='bet-types-container'>
                <div className='container-fluid'>
                    <div className='bet-types-btn-container'>
                        <div className='bet-types-btn-meron-container'>
                            <button className={`btn btn-danger btn-lg bet-types-btn-meron ${disabledButton ? 'disabled' : ''}`} onClick={() => dispatch(selectMeron())}>
                                MERON
                            </button>
                            <p className='bet-types-total-label'>{playdatabetting.meronBets}</p>
                            <p className='bet-types-payout-label'>PAYOUT: {parseFloat(playdatabetting.meronBets) * 100}</p>
                        </div>
                        <div className='bet-types-btn-draw-container'>
                            <button className={`btn btn-success btn-lg bet-types-btn-draw ${disabledButton ? 'disabled' : ''}`} onClick={() => dispatch(selectDraw())}>
                                DRAW
                            </button>
                            <p className='bet-types-draw-wins-label'>Draw Wins x 8</p>
                            <p className='bet-types-draw-wins-info'>Max. bet per player: 5000/fight</p>
                        </div>
                        <div className='bet-types-btn-wala-container'>
                            <button className={`btn btn-primary btn-lg bet-types-btn-wala ${disabledButton ? 'disabled' : ''}`} onClick={() => dispatch(selectWala())}>
                                WALA
                            </button>
                            <p className='bet-types-total-label'>{playdatabetting.walaBets}</p>
                            <p className='bet-types-payout-label'>PAYOUT : {parseFloat(playdatabetting.walaBets) * 100}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bet-types-announcement-container'>
                <div className='bet-types-player-count'>
                    <a href='#' className={`bet-types-add-player ${disabledButton ? 'disabled' : playerCounter <= 0 ? 'disabled' : ''}`} onClick={handleAddPlayer}>
                        <FaUserPlus className='addplayericon'/> ADD PLAYERS ( {playerCounter} )
                    </a>
                </div>
                <div className='bet-types-announcement-banner'>
                    <p>{playdataarena.announcement !== undefined ? playdataarena.announcement.toUpperCase() : ''}</p>
                </div>
            </div>
        </React.Fragment>
    )
}