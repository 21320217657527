import React from 'react'
import './SideDrawer.css'
import RegPlayerIcon from '../../assets/icons/playerRegistrationIcon.svg'
import WatchLiveIcon from '../../assets/icons/watchLiveIcon.svg'
import WinningsClaimIcon from '../../assets/icons/winningsClaimIcon.png'
import BettingHistoryIcon from '../../assets/icons/bettingHistoryIcon.svg'

export const SideDrawerData = [
  {
    title: 'Player Registration',
    path: '/player-registration',
    // path: '/dashboard',
    icon: <img src={RegPlayerIcon} className='menuIcons'/>,
    cName: 'nav-text'
  },
  {
    title: 'Live Streaming',
    path: '/live-streaming',
    icon: <img src={WatchLiveIcon} className='menuIcons'/>,
    cName: 'nav-text'
  },
  {
    title: 'Winnings List',
    path: '/winnings-list',
    icon: <img src={WinningsClaimIcon} className='menuIcons'/>,
    cName: 'nav-text'
  },
  {
    title: 'Betting History',
    path: '/betting-history',
    icon: <img src={BettingHistoryIcon} className='menuIcons'/>,
    cName: 'nav-text'
  },
]