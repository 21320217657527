import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSubscription } from '@apollo/client'
import { ToastContainer  } from 'react-toastify'
import SessionTimeout from '../../../sessionManager/sessionTimeout'
import { getSessionId } from '../../../utils/Auth'
import { GET_WALLET_BALANCE_REALTIME } from '../../../graphql/queries'

// COMPONENTS
import Header from '../../../components/AppBar/Header'
import SideDrawer from '../../../components/SideDrawer/SideDrawer'
import Backdrop from '../../../components/Backdrop/Backdrop'
import OperatingModal from '../../../components/Modals/Operating'
import InternetConnectionAlert from '../../../components/Alerts/InternetConnectionAlert'
import WinningsListItems from './WinningsLIstItems'

const WinningsList = props => {
    const [sideDrawerOpen, setSideDrawer] = useState(false)
    const [show, setShow] = useState(false)
    const playdata = useSelector(state => state.play)

    const walletOptions = {
        variables: { sessionId: getSessionId() },
    }

    const getWalletUpdates = useSubscription(
        GET_WALLET_BALANCE_REALTIME,
        walletOptions
    )

    useEffect(() => {
        if (playdata.fightdata.subEventId === '') {
            setShow(true)
        }
    }, [playdata.data])

    const showSidebar = () => setSideDrawer(!sideDrawerOpen)
    const clickBackdrop = () => setSideDrawer(false)
    const handleClose = () => setShow(false)

    let backdrop = sideDrawerOpen ? <Backdrop click={clickBackdrop}/> : null

    return (
        <div>
            <SessionTimeout />
            <Header drawerClickHandler={showSidebar} walletSubscription={getWalletUpdates.data} />
            <SideDrawer show={sideDrawerOpen} handleClose={showSidebar} />
            {backdrop}

            <WinningsListItems />


            <OperatingModal show={show} close={handleClose} />
            <ToastContainer autoClose={3000} hideProgressBar={false} toastClassName='dark-toast' />
            <InternetConnectionAlert/>
        </div>
    )
}

export default WinningsList