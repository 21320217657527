import React from 'react'
import Values from '../../utils/values'
import { Button, Modal } from 'react-bootstrap'
import warninglogo from '../../assets/images/unsuccessful.png'

export default function OperatingModal(props) {
    return (
        <>
            <div className='operating-container'>
                <Modal
                    onHide={props.close}
                    backdrop='static'
                    keyboard={false} show={props.show}>

                    <Modal.Body>
                        <div class='text-center'>
                            <img src={warninglogo} alt='Responsive logo' className='rounded mx-auto d-block' width={170} height={140} />
                        </div>
                        <br />
                        <div class='text-center'>
                            <p className='operating-typography-header2'> Oops!</p>
                            <p className='operating-typography-header'>{Values.operating_first_typography}</p>
                            <p className='operating-typography-header'>{Values.operating_second_typography.first_line} <br /> {Values.operating_second_typography.second_line} <br /><br /> {Values.operating_second_typography.third_line}</p>
                        </div>
                    </Modal.Body>
                    <div className='operating-button'>
                        <div className='d-grid gap-2'>
                            <Button variant='danger' size='md' onClick={props.close}>
                                Close
                            </Button>
                        </div>
                    </div>
                    <br />
                </Modal>
            </div>
        </>
    )
}