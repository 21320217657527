import * as types from './HeaderType'

const initialState = {
    loading: false,
    data: {},
    errors: null
}

export const getWalletUpdate = (state = initialState, action) => {
    switch(action.type) {
        case types.GET_WALLET_UPDATE:
            return {
                ...state,
                loading: true,
            }
        case types.GET_WALLET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case types.GET_WALLET_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        default:
            return state
    }
}