import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { destroyAuth } from '../../store/Auth/AuthAction'
import { playExpired } from '../../store/Play/PlayActions'

const BadRequest = (props) => {
    const auth = useSelector(state => state.auth.data)
    const play = useSelector(state => state.play)

    const dispatch = useDispatch()

    useEffect(() => {
        if (auth && play) {
            dispatch(destroyAuth())
            dispatch(playExpired())
        }

    }, [])

    return (
        <div>
           sample

        </div>
    )
}

export default BadRequest