import React, {useState, useRef,useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as IoIcons from 'react-icons/io5'
import { Modal, Button, Alert} from 'react-bootstrap'
import { getSessionId } from '../../../utils/Auth'
import { useSubscription } from '@apollo/client'
import { GET_WALLET_BALANCE_REALTIME } from '../../../graphql/queries'
import 'react-toastify/dist/ReactToastify.css'
import AddForm from './AddForm'
import Players from './Players'
import Header from '../../../components/AppBar/Header'
import SideDrawer from '../../../components/SideDrawer/SideDrawer'
import Backdrop from '../../../components/Backdrop/Backdrop'

import NoPlayerIcon from '../../../assets/images/no_player.svg'

const RegPlayers = props => {
    const [sideDrawerOpen, setSideDrawer] = useState(false)
    const [show, setShow] = useState(false)
    const [active, setActive] = useState(false)
    const inputEl = useRef("")
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const playerList = useSelector(state => state.getAgentPlayers.data)
    const [searchTerm, setSearchTerm] = useState('')
 
    const showSidebar = () => setSideDrawer(!sideDrawerOpen)
    const clickBackdrop = () => setSideDrawer(false)
    const [searchResult, setSearchResult] = useState([]);

    let backdrop = sideDrawerOpen ? <Backdrop click={clickBackdrop} /> : null

    let history = useHistory();

    useEffect(() => {
        handleClose()
    }, [playerList])

    const walletOptions = {
        variables: { sessionId: getSessionId() },
    }

    const getWalletUpdates = useSubscription(
        GET_WALLET_BALANCE_REALTIME,
        walletOptions
    )
    const getSearchTerm = () => {
        
        if (inputEl.current.value !== "")
        {
            setSearchTerm(inputEl.current.value)
            const newPlayersList = playerList.filter((player) => {
               
                return Object.values(player)
                .join(" ")
                .toLowerCase()
                .includes(inputEl.current.value.toLowerCase());
                
            });
            setSearchResult(newPlayersList);
        }
        else{
            setSearchResult(playerList);
        }
    };

    return (
        <>  
            <Header drawerClickHandler={showSidebar} walletSubscription={getWalletUpdates.data} />
            <SideDrawer show={sideDrawerOpen} handleClose={showSidebar} />
            {backdrop}
            <div className='registerPlayerContainer'>
                <div className='registerPlayerHeader'>
                    <h3 className={'pageTitle'}>Player Registration</h3>
                    <div className='searchbar'>
                        <input ref={inputEl} type= 'text' className={'input active'}
                            onChange={getSearchTerm} placeholder='Search Player or Number'/>
                        <IoIcons.IoSearch className='searchBtn' onClick={() => setActive(!active)}/>
                    </div>

                    <div className='tableTitle'>
                        <div className='countCont'>
                            <h6> You have { playerList.length } Player/s</h6>
                        </div>
                        <div className='addBtnCont'>
                            <Button onClick={handleShow} className="addBtn btn-danger" data-toggle="modal" id='addPlayerBtn'> Add Player</Button>		
                        </div>
                    </div>
                </div>
                {/* <div className="navbar navbar-dark bg-transparent">
                    <div className='container-fluid navbar-icons'>
                        <h3 className= {active? 'tempHiddenTitle': 'pageTitle'}>Player Registration</h3>
                        <div className='searchContainer'>
                            <div className='searchbar'>
                                <input ref={inputEl} type= 'text' className={active ? 'input active' : 'input' }
                                onChange={getSearchTerm} placeholder='Search Player Here'/>
                                <IoIcons.IoSearch className='searchBtn'
                                onClick={() => setActive(!active)}/>
                            </div>
                        </div>
                    </div>
                </div> */}
                

                {
                    playerList.length <= 0 ?
                        <section className='noPlayerSection'>
                            <img src={NoPlayerIcon} alt='icon' className='noPlayerIcon' />
                            <div className='noPlayerMessage'>
                                <p>You don’t have any registered players yet</p>
                                <p>Tap the ADD PLAYER button now to start.</p>
                            </div>
                        </section> :

                    <table className="table table-hover playerTable">
                        <thead>
                            <tr>
                                <th>Player Name</th>
                                <th>Contact Number</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                            searchTerm.length <1 ?
                            playerList.map(player => (
                                <tr key={player.agentPlayerId}>
                                    <Players player={player} />
                                </tr>
                            ))  :
                            searchResult.length <= 0 ?
                                <p className='noMatches'>Oops! No matches found.</p>
                                :
                            searchResult.map(player => (
                                <tr key={player.agentPlayerId}>
                                    <Players player={player} />
                                </tr>
                            ))
                            }
                                
                        </tbody>
                    </table>

                }

                {/* Add Modal */}
                <Modal show = {show} onHide={handleClose} className='player-modal' centered>
                    <Modal.Header>
                        <Modal.Title>
                        <div className='modalTitle'>Player Details</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddForm />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default RegPlayers