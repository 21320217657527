import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function GuestRoute({ component: Component, restricted, ...rest }) {
    const auth = useSelector(state => state.auth)

    return (
        <Route {...rest} render={props => (
            auth.data && restricted ?
                <Redirect to='/dashboard' /> : <Component {...props} />
        )
        } />
    )
}

export default GuestRoute