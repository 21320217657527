import React , { useEffect } from 'react'
import LineLoader from '../../components/LineLoader/LineLoader'
import { useDispatch, useSelector } from 'react-redux'
import { initiateAuth } from '../../store/Auth/AuthAction'
import { storeSession } from '../../utils/Auth'
import { useLocation } from 'react-router-dom'
import history from '../../utils/history'
import moment from 'moment'

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export default function Loader(props) {
    const query = useQuery()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    // const validation = new Validator(data, rules)


    useEffect(() => {
        if (!query.get('session_id')) {
            history.push('/404')
        }
        
        if(auth.data == null) {
            storeSession(query.get('session_id'))
            dispatch(initiateAuth({ sessionId: query.get('session_id') }))

            sessionStorage.setItem('validate', true)
            let timeStamp = moment()
            
            localStorage.setItem('lastTimeStamp', timeStamp)
            localStorage.removeItem('playerSection')
        } else {
            history.push('/dashboard')
        }
    }, [])

    return (
        <div>
            Space for loader screen *removed material-ui*
            <LineLoader />
        </div>
    )
}