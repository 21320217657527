import processActions from '../../utils/ProcessAction'
import { GET_AUTH, FAILED_AUTH, START_AUTH } from './AuthTypes'
import { login as InitiateService } from './AuthServices'
import { all, put, select, takeEvery } from '@redux-saga/core/effects'
import {  GET_MODE } from '../Play/PlayType'
import * as selectors from '../selectors'
import history from '../../utils/history'

function* login({ payload }) {
    yield processActions({
        params: payload.sessionId,
        service: InitiateService,
        success: GET_AUTH,
        failed: FAILED_AUTH
    })

    let isAuth = yield (select(selectors.Authenticated))
    if (isAuth) {
        yield put({
            type: GET_MODE,
            payload
        })
    }
}

function authFailed() {
    history.push('/400')
}

export default function* watchAuthRequests() {
    yield all([
        takeEvery(START_AUTH, login),
        takeEvery(FAILED_AUTH, authFailed)
    ])
}