import React from 'react'
import Values from '../../utils/values'
import { Button, Modal } from 'react-bootstrap'
import warninglogo from '../../assets/images/unsuccessful.png'

export default function ClaimModal(props) {
    return (
        <>
            <Modal className=' claim-container claim-modal'
                onHide={props.close}
                backdrop='static'
                keyboard={false} show={props.show}>

                <Modal.Body>
                    <div className='text-center'>
                        <img src={warninglogo} alt='Responsive logo' className='rounded mx-auto d-block' width={170} height={140} />
                    </div>
                    <br />
                    <div className='text-center'>
                        <p className='claim-typography-header'>{Values.claimwinnings}</p>
                    </div>
                </Modal.Body>
                <div className='claim-button-body'>
                    <div className='d-grid gap-2'>
                        <Button className='claim-button' variant='danger' size='md' onClick={props.confirm}>
                            {Values.claimbuttonyes}
                        </Button>

                        <Button className='claim-button-outline' size='md' onClick={props.close}>
                            {Values.claimbuttonno}
                        </Button>
                    </div>
                </div>
                <br />
            </Modal>
        </>
    )
}