import api from '../../utils/Api'

export const getAgentPlayers = async (sessionID) => {
    try {
        const res =  await api({
            url: `/v1/players?session_id=${sessionID}`,
            method: 'GET'
        })
        const data = res.data
        return data

    } catch(err) {
        throw err
    }
}

export const placeBets = async (payload) => {
    try {
        const res = await api({
            url: `/v1/bets`,
            method: 'POST',
            data: payload
        })
        const data = res.data
        return data
    } catch(err) {
        throw err
    }
}

export const createAgentPlayer = async (payload) => {
    try {
        const res = await api({
            url: `/v1/players`,
            method: 'POST',
            data: payload
        })
        const data = res.data
        return data
    } catch(err) {
        throw err
    }
}

export const deleteAgentPlayer = async (payload) => {
    try {
        const res = await api({
            url: `/v1/players`,
            method: 'DELETE',
            data: payload
        })
        const data = res.data
        return data
    } catch(err) {
        throw err
    }
}

export const editAgentPlayer = async (payload) => {
    try {
        const res = await api({
            url: `/v1/players`,
            method: 'PUT',
            data: payload
        })
        const data = res.data
        return data
    } catch(err) {
        throw err
    }
}