import api from '../../utils/Api'

export const getWalletUpdate = async (sessionId) => {
    try {
        const result = await api({
            url: process.env.REACT_APP_MEBET_API_BASE_URL + `/v1/users/wallet?session_id=${sessionId}`,
            method: 'GET'
        })

        return result.data
    } catch (err) {
        console.log(err)
    }
}