import React from 'react'
import './SideDrawer.css'
import { Link } from 'react-router-dom'
import { SideDrawerData } from './SideDrawerData'
import SideDrawerHeader  from './SideDrawerHeader'

const SideDrawer = props => {
  let drawerClass = 'side-drawer'

  if (props.show) drawerClass = 'side-drawer open'
  
  // const handleClick = () => {
  //   drawerClass = 'side-drawer'
  //   console.log('Click happened')
  // }

  return ( 
    <nav className= {drawerClass}>
      <SideDrawerHeader clickHandler = {props.handleClose}/>
      <ul className='sidebarList' onClick={props.handleClose}>
        {SideDrawerData.map((item, index) => {
          return (
            <li key={index} className='row'>
              <Link to={item.path}>
                <div id='icon'>{item.icon}</div>
                <div id='title'><span>{item.title}</span></div>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav> 
  )
}

export default SideDrawer