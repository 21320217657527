import React , { useEffect } from 'react'
import { Router, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import history from './utils/history'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// routes
import GuestRoute from './routes/GuestRoute'
import AuthRoute from './routes/AuthRoute'
// containers
import Loader from './containers/Loader/Loader'
import BadRequest from './containers/Errors/BadRequest'

import Agent from './containers/Play/Agent/Dashboard'
import User from './containers/Play/User/Dashboard'
import PlayerReg from './containers/Pages/RegPlayers/RegPlayers'
import LiveStreaming from './containers/Pages/LiveStreaming/LiveStreaming'
import WinningsList from './containers/Pages/WinningsList/WinningsList'
import BettingHistory from './containers/Pages/BettingHistory/BettingHistory'

import { getSubEventId } from './utils/Auth'

function App() {
  const Swal2 = withReactContent(Swal)
  const selectedType = useSelector(state => state.play)

  useEffect(() => {
    document.title = 'Pitmasters'

    localStorage.openpages = Date.now()
    window.addEventListener('storage', (e) => {
      if (e.key == 'openpages') {
        localStorage.page_available = Date.now()
      }

      if(e.key == 'page_available') {
        Swal2.fire({
          icon: 'warning',
          title: 'Oops!',
          text: 'Multiple tabs detected.',
          allowOutsideClick: false,
          showConfirmButton: false
        })
      }
    }, false)

  }, [])


  return (
    <div className='App'>
      <Router history={history}>
        <Switch>
          <GuestRoute path={'/session'} restricted={false} component={Loader} />
          <GuestRoute path={'/400'} restricted={false} component={BadRequest} />
          <AuthRoute path={'/dashboard'} restricted={false} component={selectedType.data && selectedType.data.type !== 'AGENT' ? User : Agent} />
          <AuthRoute path={'/player-registration'} restricted={false} component={PlayerReg} />
          <AuthRoute path={'/live-streaming'} restricted={false} component={LiveStreaming} />
          <AuthRoute path={'/winnings-list'} restricted={false} component={WinningsList} />
          <AuthRoute path={'/betting-history'} restricted={false} component={BettingHistory} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
