import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { placeBets } from '../store/BetFields/BetFieldServices'
import { getSessionId, getSubEventId } from '../utils/Auth'
import generateReferenceId from '../utils/ReferenceIdGenerator'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FaSpinner } from 'react-icons/fa'

import { getWalletUpdate } from '../store/AppBar/HeaderAction'

import SuccessIcon from '../assets/images/successful_icon.svg'
import UnsuccessfulIcon from '../assets/images/unsuccessful.png'

export default function PlaceBetButton(props) {
    const Swal2 = withReactContent(Swal)
    const dispatch = useDispatch()
    const playdata = useSelector(state => state.play)
    const walletBalanceUpdate = useSelector(state => state.getWalletUpdate.data)
    const selecTedBetType = useSelector(state => state.betTypeButtonSelect)
    const disabledButton = useSelector(state => state.betTypeDisableButton)
    const [isLoading, setIsLoading] = useState(false)

    const handlePlaceBet = () => {
        setIsLoading(true)
        props.placeBet.forEach(x => delete x.indexKey)
        
        let totalPlaceBet = props.placeBet.reduce((a,b) => a + b.amount, 0)
        
        let payload = {
            sessionId: getSessionId(),
            referenceId: generateReferenceId(),
            subEventId: getSubEventId(),
            bet: selecTedBetType === 'MERON_SELECTED' ? 'MERON' : selecTedBetType === 'WALA_SELECTED' ? 'WALA' : 'DRAW',
            amount: parseInt(totalPlaceBet),
            players: props.placeBet // playerID and amount
        }

        placeBets(payload)
            .then((res) => {
                Swal2.fire({
                    imageUrl: SuccessIcon,
                    imageHeight: 200,
                    title: 'Bet Successful!',
                    html: `<p class='success-message'>You have successfully placed your bet on <span class=${selecTedBetType === 'MERON_SELECTED' ? 'meron-tag' 
                        : selecTedBetType === 'WALA_SELECTED' ? 'wala-tag' : 'draw-tag'}>MERON</span> for Fight #${playdata.fightdata.fightNumber} with Reference #${res.data.referenceId}</p>`,
                    showConfirmButton: false,
                    timer: 3000
                }).then(() => {
                    props.totalBetFunc()
                    props.tempFunc(true)
                })
            })
            .catch((err) => {
                console.log('___err.message', err.message)
                Swal2.fire({
                    imageUrl: UnsuccessfulIcon,
                    imageHeight: 200,
                    text: err.response == undefined ? err.message : err.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
                props.tempFunc(true)
            })
            .finally(() => {
                // reset state
                setIsLoading(false)
            })
    }


    useEffect(() => {
        if(props.walletSubscription !== undefined) {
            if (props.walletSubscription.onUpdateWalletBalance.updateOnWallet.toLowerCase() === 'true') {
                dispatch(getWalletUpdate())
            }
        }
    }, [props.walletSubscription])

    return (
        <div className='place-bet-button-container'>
            <div className='container-fluid'>
                <div className='place-bet-button-points-container'>
                    <span className='place-bet-button-points-label'>Total Available Points to Bet</span>
                    <span className='place-bet-button-points-label'>
                        {
                            props.walletSubscription !== undefined ? walletBalanceUpdate.balance : playdata.data.balance
                        }
                    </span>
                </div>
                <button className={`btn btn-block ${selecTedBetType === 'MERON_SELECTED' ? 'place-bet-btn-meron' : selecTedBetType === 'WALA_SELECTED' ? 'place-bet-btn-wala' : selecTedBetType === 'DRAW_SELECTED' ? 'place-bet-btn-draw' : ''}
                    ${props.disableBet ? 'disabled' : disabledButton ? 'disabled' : isLoading ? 'disabled' : ''} `}
                    onClick={handlePlaceBet}
                    >PLACE BET ON
                        {selecTedBetType === 'MERON_SELECTED' ? ' MERON ' : selecTedBetType === 'WALA_SELECTED' ? ' WALA ' : selecTedBetType === 'DRAW_SELECTED' ? ' DRAW ' : ''}
                        { isLoading ? <FaSpinner className='spinn' /> : ''}
                </button>
            </div>
        </div>
    )
}