import { getBettingHistory } from './BettingHistoryServices'
import { getBettingHistorySuccess, getBettingHistoryFailed } from './BettingHistoryActions'
import { GET_BETTING_HISTORY } from './BettingHistoryType'
import { getSessionId } from '../../utils/Auth'
import { all, put, takeLatest } from '@redux-saga/core/effects'
import { call } from 'redux-saga/effects'

function* getBettingHistoryRequest() {
    try {
        const response = yield call(getBettingHistory, getSessionId())
        yield put(getBettingHistorySuccess(response.data))
    } catch(err) {
        yield put(getBettingHistoryFailed(err))
    }
}

export default function* watchBettingHistoryRequest() {
    yield all([
        takeLatest(GET_BETTING_HISTORY, getBettingHistoryRequest)
    ])
}