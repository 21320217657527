import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useQuery, useSubscription } from '@apollo/client'
import { getSessionId } from '../../../utils/Auth'
import { GET_WALLET_BALANCE_REALTIME, GET_UPDATE_EVENT, GET_FIGHT_UPDATE, GET_BETTING_POSTED,
        GET_SEQUENCE_DETAILS, GET_BACCARAT_DETAILS, GET_BACCARAT_QUERY, GET_SEQUENCE_QUERY } from '../../../graphql/queries'
import { getAgentPlayers } from '../../../store/BetFields/BetFieldActions'
import { webSocketFight, webSocketArena, webSocketBetting, 
    webSocketSequence, webSocketBaccarat, querySequence, queryBaccarat } from '../../../store/Play/PlayActions'
import SessionTimeout from '../../../sessionManager/sessionTimeout'

// COMPONENTS
import Header from '../../../components/AppBar/Header'
import FightDetailsHeader from '../../../components/AppBar/FightDetailsHeader'
import BetTypesComponent from '../../../components/Bet_Types'
import BetFields from '../../../components/Bet_Fields'
import SideDrawer from '../../../components/SideDrawer/SideDrawer'
import Backdrop from '../../../components/Backdrop/Backdrop'
import OperatingClose from '../../../components/Typography/OperatingClose'
import InternetConnectionAlert from '../../../components/Alerts/InternetConnectionAlert'

const Dashboard = props => {
    const dispatch = useDispatch()
    const [sideDrawerOpen, setSideDrawer] = useState(false)
    const playdata = useSelector(state => state.play)

    const walletOptions = {
        variables: { sessionId: getSessionId() },
    }

    const options = {
        variables: {
            gameType: 'ESABONG',
        }
    }

    const getFightUpdate = useSubscription(
        GET_FIGHT_UPDATE,
        options
    )

    const getSequenceUpdate = useSubscription(
        GET_SEQUENCE_DETAILS,
        options
    )

    const getBaccaratUpdate = useSubscription(
        GET_BACCARAT_DETAILS,
        options
    )

    const getBaccarat = useQuery(
        GET_BACCARAT_QUERY,
        options
    )

    const getSequence= useQuery(
        GET_SEQUENCE_QUERY,
        options
    )

    const getBettingPosted = useSubscription(
        GET_BETTING_POSTED,
        options
    )

    const getArenaUpdateEvent = useSubscription(
        GET_UPDATE_EVENT,
        options
    )

    const getWalletUpdates = useSubscription(
        GET_WALLET_BALANCE_REALTIME,
        walletOptions
    )


    useEffect(() => {
        if (playdata.fightdata.subEventId !== '') {
            const header = document.getElementById('bannerGroup')
            const sticky = header.offsetTop
            const scrollCallBack = window.addEventListener('scroll', () => {
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            })
            return () => {
                window.removeEventListener('scroll', scrollCallBack);
            }
        }
    }, [playdata.fightdata.subEventId])

    useEffect(() => {
        dispatch(getAgentPlayers())

        if (getFightUpdate.data) {
            dispatch(webSocketFight(getFightUpdate.data.onPushFight))
        }

        if (getBettingPosted.data) {
            dispatch(webSocketBetting(getBettingPosted.data.onBettingPosted))
        }

        if (getArenaUpdateEvent.data) {
            dispatch(webSocketArena(getArenaUpdateEvent.data.onPushArena))
        }
        
        if (getSequenceUpdate.data) {
            dispatch(webSocketSequence(getSequenceUpdate.data.onSequenceUpdate))
        }
        if (getBaccaratUpdate.data) {
            dispatch(webSocketBaccarat(getBaccaratUpdate.data.onBaccaratUpdate))
        }

        if (getSequence.data) {
            dispatch(querySequence(getSequence.data.getSequence))
        }
        if (getBaccarat.data) {
            dispatch(queryBaccarat(getBaccarat.data.getBaccarat))
        }

    }, [getFightUpdate.data, getBettingPosted.data, getArenaUpdateEvent.data,
        getSequenceUpdate.data, getBaccaratUpdate.data, getBaccarat.data, getSequence.data])

    const showSidebar = () => setSideDrawer(!sideDrawerOpen)
    const clickBackdrop = () => setSideDrawer(false)


    let backdrop = sideDrawerOpen ? <Backdrop click={clickBackdrop} /> : null

    return (

        <div>
            {
                playdata.fightdata.subEventId === '' || playdata.fightdata.subEventId === undefined ?
                    <>
                        <SessionTimeout />
                        <Header drawerClickHandler={showSidebar} walletSubscription={getWalletUpdates.data} />
                        <SideDrawer show={sideDrawerOpen} handleClose={showSidebar} />
                        {backdrop}
                        <OperatingClose />
                    </> :
                    <>
                        <SessionTimeout />
                        <Header drawerClickHandler={showSidebar} walletSubscription={getWalletUpdates.data} />
                        <SideDrawer show={sideDrawerOpen} handleClose={showSidebar} />
                        <div className={`banner-group`} id='bannerGroup'>
                            <FightDetailsHeader arenaUpdate={getArenaUpdateEvent.data} />
                            <BetTypesComponent bettingPosted={getBettingPosted.data} />
                        </div>


                        <BetFields walletSubscription={getWalletUpdates.data} />
                        {backdrop}
                        <ToastContainer autoClose={3000} hideProgressBar={false} toastClassName='dark-toast' />
                        <InternetConnectionAlert />
                    </>
            }

        </div>
    )
}

export default Dashboard