const randomString = (length = 6) => {
    let result = ''
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    for (var i = length; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)]
    }

    return result
}

const generateReferenceId = () => {
    const today = new Date()
    const year = today.getFullYear() % 100 // get only the last 2 digits
    let dayOfYear = String(Math.ceil(
        (today - new Date(today.getFullYear(), 0, 1)) / 86400000
    )).padStart(3, '0')

    return year.toString() + dayOfYear + randomString(7)
}

export default generateReferenceId