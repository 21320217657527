import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment,
} from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { destroyAuth } from '../../src/store/Auth/AuthAction'
import { playExpired } from '../../src/store/Play/PlayActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { disabledBetTypeButton } from '../../src/store/BetTypes/BetTypeActions'
import { getMode } from '../store/Play/PlayServices'
import { getSessionId } from '../utils/Auth'

import UnsuccessfulIcon from '../../src/assets/images/unsuccessful.png'

const SessionTimeout =()=> {
    const Swal2 = withReactContent(Swal)
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(state => state.auth.data)
    const playdata = useSelector(state => state.play)
    const walletBalanceUpdate = useSelector(state => state.getWalletUpdate.data)

    const [events, setEvents] = useState(['click', 'load', 'scroll'])
    const [second, setSecond] = useState(0)

    let timeStamp
    let warningInactiveInterval = useRef()
    let startTimerInterval = useRef()

    // start inactive check
    let timeChecker = () => {
        startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = localStorage.getItem('lastTimeStamp')
        warningInactive(storedTimeStamp)
        }, 60000)
    }

    // warning timer
    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current)
    
        warningInactiveInterval.current = setInterval(() => {
            const maxTime = 15 // Maximum ideal time given before logout 
            const popTime = 1 // remaining time (notification) left to logout.
        
            const diff = moment.duration(moment().diff(moment(timeString)))
            const minPast = diff.minutes()
            const leftSecond = 60 - diff.seconds()
        
            if (minPast === popTime) {
                setSecond(leftSecond)
            }
        
            if (minPast === maxTime) {
                clearInterval(warningInactiveInterval.current)
                localStorage.removeItem('lastTimeStamp')
                // logout function here
                handleCheckBalance()
                handleSwalForSession()
                dispatch(destroyAuth())
                dispatch(playExpired())
                
            }
        }, 1000)
    }

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current)
        clearInterval(warningInactiveInterval.current)

        if (isAuthenticated !== null) {
            if (localStorage.getItem('lastTimeStamp') == null || localStorage.getItem('lastTimeStamp') === undefined) {
                timeStamp = moment()
                localStorage.setItem('lastTimeStamp', timeStamp)
            } else {
                return
            }
            
        } else {
            clearInterval(warningInactiveInterval.current)
            localStorage.removeItem('lastTimeStamp')
        }
        timeChecker()
    }, [isAuthenticated])

    // wallet balance checker
    const handleCheckBalance = () => {
        if (walletBalanceUpdate.balance !== undefined) {
            if (walletBalanceUpdate.balance < 100 ) {
                handleSwalForWalletBalance()
            }
        } else {
            if (playdata.data.balance < 100) {
                handleSwalForWalletBalance()
            }
        }
    }

    // Life cycle hook
    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer)
        })

        timeChecker()
        
        return () => {
            clearTimeout(startTimerInterval.current)
        }

    }, [resetTimer, events, timeChecker])


    const handleValidate = () => {
        getMode((getSessionId()))
            .then((res) => {
                console.log('validate::', res)
            })
            .catch((err) => {
                if (err.response.status == 400 || err.response.status == 401) {
                    handleSwalForSession()
                    dispatch(destroyAuth())
                    dispatch(playExpired())
                    localStorage.removeItem('lastTimeStamp')
                }
            })
    }


    useEffect(() => {
        let isAuthenticated = sessionStorage.getItem('validate')
        if (isAuthenticated === 'true') {
            handleCheckBalance()
            // execute validation after 15mins
            setTimeout(() => {
                handleValidate()
            }, 900000)
            
        } else {
            // execute validation
            let storedTimeStamp = localStorage.getItem('lastTimeStamp')
            let minAgo = moment(storedTimeStamp).startOf('minute').fromNow(true)
            let splitMin = minAgo.split(' ')
            
            if (splitMin[0] >= 15 ) {
                handleValidate()
            } else {
                let timer = 15 - splitMin[0]
                let timeout = timer * 60000
                
                setTimeout(() => {
                    handleValidate()   
                }, timeout)
            }
        }
    }, [])

    // Prompt message for wallet balance
    const handleSwalForWalletBalance = () => {
        Swal2.fire({
            imageUrl: UnsuccessfulIcon,
            imageHeight: 200,
            title: 'Oops!',
            text: `You don't have enough points to continue your livestream at Pitmasters Live. To continue, kindly top up
                    at least 100 points on your account. Thank you!`,
            confirmButtonText: 'CLOSE',
            confirmButtonColor: '#EE0000'
        }).then((result) => {
            if (result.isConfirmed) {
                // disble buttons after closing modal
                dispatch(disabledBetTypeButton('DISABLED_BUTTON'))
                
            }
        })
    }

    const handleSwalForSession = () => {
        Swal2.fire({
            title: 'Oops!',
            text: `You have been logged out due to inactivity. To continue, you may access the website through your Bayad app. Thank you!`,
            confirmButtonText: 'CLOSE',
            confirmButtonColor: '#EE0000'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(disabledBetTypeButton('DISABLED_BUTTON'))
            }
        })
    }
    
    return <Fragment />
}

export default SessionTimeout