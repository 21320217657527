import React, { useState } from 'react'
import { claimWinnings } from '../../../store/Play/PlayServices'
import { getSessionId } from '../../../utils/Auth'
import ClaimModals from '../../../components/Modals/ClaimModals'
import moment from 'moment'

export default function WinningsListItems(props) {
    const [openConfirm, setopenConfirm] = useState(false)
    const closeClaimModal = () => setopenConfirm(false)

    const claimButton = () => {
        console.log()
        setopenConfirm(true)
    }
    const claimConfirmButton = () => {
        let payload = {
            sessionId: getSessionId(),
            referenceId: props.data.referenceId
        }

        claimWinnings(payload)
            .then((res) => {
                setopenConfirm(false)

            })
            .catch((err) => {
                setopenConfirm(false)
            }).finally(() => {
                // reset state
                window.location.reload(true)
            })
    }

    return (
        <>

            <tr className='winning-list-tr'>
                <td>
                    <div className='winning-list-body'>
                        <div className='winning-list-details'>
                            <p className='winning-list-name'>{props.data.player.name}</p>
                            <p className='winning-list-fight-number'>Fight# {props.data.subevent.number}
                                <span className={`winning-list-bet-type ${props.data.subevent.result === 'MERON' ? 'bet-meron'
                                    : props.data.subevent.result === 'DRAW' ? 'bet-draw' :
                                        props.data.subevent.result === 'WALA' ? 'bet-wala' : 'bet-cancelled'}`}> {props.data.subevent.result}</span>
                            </p>
                            <p className='winning-list-date'>{moment(props.data.createdAt).format('MMMM DD YYYY h:mm a')}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <div className='winning-list-body'>
                        <div className='winning-list-reference'>
                            <p className='winning-list-name'>{props.data.player.mobileNumber}</p>
                            <p className='winning-list-reference-number-label'>Reference#</p>
                            <p className='winning-list-reference-number'>{props.data.referenceId}</p>
                        </div>
                    </div>
                </td>

                <td>
                    <div className='winning-list-action'>
                        {
                            props.data.isClaimed ? <>
                                <button className='btn btn-light' disabled><center>CLAIMED</center></button>
                            </> : <>
                                <button className='btn btn-success winning-button-name button-claim' onClick={claimButton}><center>CLAIM {(Number(props.data.amount)).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}</center></button>
                            </>
                        }

                    </div>
                </td>
            </tr>
            
            {
                openConfirm ?  <ClaimModals show={openConfirm} close={closeClaimModal} confirm={claimConfirmButton} /> : <></>
            }          
        </>
    )
}