import { gql } from '@apollo/client'

export const GET_WALLET_BALANCE = gql`
	query MyQuery($userId: String!) {
		getLatestWalletBalance(userId: $userId) {
            userId
            balance
            updatedAt
		}
	}
`

export const GET_WALLET_BALANCE_REALTIME = gql`
    subscription MySubscription($sessionId: String!){
        onUpdateWalletBalance(sessionId: $sessionId) {
            sessionId
            updateOnWallet
        }
    }
`

export const GET_UPDATE_EVENT = gql`
    subscription MySubscription($gameType: String!){
        onPushArena(gameType:$gameType) {
            gameType
            event
            announcement
		}
	}
`

export const GET_BETTING_POSTED = gql`
    subscription MySubscription($gameType: String!){
        onBettingPosted(gameType:$gameType) {
            gameType
            meronOdds
            walaOdds
            walaBets
            drawBets
            meronBets
            betCount
		}
	}
`

export const GET_FIGHT_UPDATE = gql`
    subscription MySubscription($gameType: String!){
        onPushFight(gameType:$gameType) {
            gameType
            subEventId
            eventId
            openBet
            fightNumber
            status
            winner
            waitingDecision
            meronEqualPoint
            walaEqualPoint
		}
	}
`

export const GET_BACCARAT_DETAILS = gql`

    subscription MySubscription($gameType: String!){
        onBaccaratUpdate(gameType:$gameType) {
            totalMeron
            totalWala
            totalDraw
            totalCancelled
            data
    }
}`



export const GET_SEQUENCE_DETAILS =  gql`
    subscription MySubscription($gameType: String!){
        onSequenceUpdate(gameType:$gameType) {
            totalMeron
            totalWala
            totalDraw
            totalCancelled
            data
    }
}`


export const GET_BACCARAT_QUERY = gql`
    query MyQuery($gameType: String!) {
        getBaccarat(gameType: $gameType) {
            gameType
            totalMeron
            totalWala
            totalDraw
            totalCancelled
            data
        }
    }
`

export const GET_SEQUENCE_QUERY = gql`
    query MyQuery($gameType: String!) {
        getSequence(gameType: $gameType) {
            gameType
            totalMeron
            totalWala
            totalDraw
            totalCancelled
            data
        }
    }
`