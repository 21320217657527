import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { removePlayer } from '../store/BetFields/BetFieldActions'
import PlaceBetButton from './PlaceBetButton'
import { FaTrash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'

export default function BetFields(props) {
    const Swal2 = withReactContent(Swal)

    const selecTedBetType = useSelector(state => state.betTypeButtonSelect)
    const disabledButton = useSelector(state => state.betTypeDisableButton)
    const playdata = useSelector(state => state.play.data)
    const walletBalanceUpdate = useSelector(state => state.getWalletUpdate.data)
    const playerList = useSelector(state => state.getAgentPlayers.data)
    const playerInteraction = useSelector(state => state.playerInteraction)

    const dispatch = useDispatch()
    const [meronBets, setMeronBets] = useState([])
    const [walaBets, setWalaBets] = useState([])
    const [drawBets, setDrawBets] = useState([])

    const [currentBet, setCurrentBet] = useState({})
    const [previousBet, setPreviousBet] = useState({})

    const [disableBet, setDisableBet] = useState(true)
    const [selectDefaultVal, setSelectDefaultVal] = useState('SELECT PLAYER')

    const [disableMeronDenomination, setDisableMeronDenomination] = useState(true)
    const [disableDrawDenomination, setDisableDrawDenomination] = useState(true)
    const [disableWalaDenomination, setDisableWalaDenomination] = useState(true)
    const [disableMeronRemove, setDisableMeronRemove] = useState(false)
    const [disableDrawRemove, setDisableDrawRemove] = useState(false)
    const [disableWalaRemove, setDisableWalaRemove] = useState(false)

    const [playerSection, setPlayerSection] = useState([0,1,2,3]) // default player section of 4
    const [index, setIndex] = useState(Number)
    const [customAmount, setCustomAmount] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    // denomination fields
    const [betFields, setBetFields] = useState([
        {
            label: '100',
            value: 100
        },
        {
            label: '200',
            value: 200
        },
        {
            label: '300',
            value: 300
        },
        {
            label: '500',
            value: 500
        },
        {
            label: '1,000',
            value: 1000
        },
        {
            label: '3,000',
            value: 3000
        },
        {
            label: '5,000',
            value: 5000
        },
        {
            label: '10,000',
            value: 10000
        },
        {
            label: '15,000',
            value: 15000
        },
        {
            label: '20,000',
            value: 20000
        }
    ])

    const [meronFilterPlayer, setMeronFilterPlayer] = useState([])
    const [drawFilterPlayer, setDrawFilterPlayer] = useState([])
    const [walaFilterPlayer, setWalaFilterPlayer] = useState([])

    const handleSelectPlayer = (e) => {
        let dropdownValue = e.target.value.split('_')
        let obj = {
            playerName: dropdownValue[0],
            playerId: dropdownValue[1],
            indexKey: dropdownValue[2]
        }

        let indexArr
        switch(selecTedBetType) {
            case 'MERON_SELECTED':
                indexArr = meronFilterPlayer.findIndex((el) => el.indexKey === obj.indexKey)
                if (indexArr === -1) {
                    setMeronFilterPlayer([...meronFilterPlayer, obj])
                } else {
                    meronFilterPlayer[indexArr] = obj
                }
                
                setDisableMeronDenomination(false)
                break
            case 'WALA_SELECTED':
                indexArr = walaFilterPlayer.findIndex((el) => el.indexKey === obj.indexKey)
                if (indexArr === -1) {
                    setWalaFilterPlayer([...walaFilterPlayer, obj])
                } else {
                    walaFilterPlayer[indexArr] = obj
                }
                
                setDisableWalaDenomination(false)
                break
            case 'DRAW_SELECTED':
                indexArr = drawFilterPlayer.findIndex((el) => el.indexKey === obj.indexKey)
                if (indexArr === -1) {
                    setDrawFilterPlayer([...drawFilterPlayer, obj])
                } else {
                    drawFilterPlayer[indexArr] = obj
                }

                setDisableDrawDenomination(false)
                break
            default:
                return
        }
    }

    // for handling custom amount text field
    const handleInput = (e) => {
        const id = e.target.id.split('_')
        const name = e.target.name
        const value = e.target.value.replace(/[^\d]/, "")
        const amount = value.slice(0, e.target.maxLength)
        setCustomAmount({...customAmount, [name]: amount})
        setDisableBet(false)
        let payload = {
            id: id[0],
            amount: parseInt(amount),
            indexKey: id[1]
        }

        // set current bet
        setCurrentBet({...currentBet, [payload.id+'_'+payload.indexKey]: payload.amount})

        let indexArr
        switch(selecTedBetType) {
            case 'MERON_SELECTED':
                indexArr = meronBets.findIndex((el) => el.id === id[0])
                if (indexArr === -1) {
                    setMeronBets([...meronBets, payload])
                } else {
                    meronBets[indexArr] = payload
                }
                break
            case 'DRAW_SELECTED':
                indexArr = drawBets.findIndex((el) => el.id === id[0])
                if (indexArr === -1) {
                    setDrawBets([...drawBets, payload])
                } else {
                    drawBets[indexArr] = payload
                }
                break
            case 'WALA_SELECTED':
                indexArr = walaBets.findIndex((el) => el.id === id[0])
                if (indexArr === -1) {
                    setWalaBets([...walaBets, payload])
                } else {
                    walaBets[indexArr] = payload
                }
                break
            default:
                return
        }


    }

    // for handling denomination field
    const handleRadioButton = (id, val, index) => {
        setDisableBet(false)
        
        let payload = {
            id,
            amount: val,
            indexKey: index
        }

        // set current bet
        setCurrentBet({...currentBet, [id+'_'+index]: val})


        let indexArr
        switch(selecTedBetType) {
            case 'MERON_SELECTED':
                indexArr = meronBets.findIndex((el) => el.id === id)
                if (indexArr === -1) {
                    setMeronBets([...meronBets, payload])
                } else {
                    meronBets[indexArr] = payload
                }
                break
            case 'DRAW_SELECTED':
                indexArr = drawBets.findIndex((el) => el.id === id)
                if (indexArr === -1) {
                    setDrawBets([...drawBets, payload])
                } else {
                    drawBets[indexArr] = payload
                }
                break
            case 'WALA_SELECTED':
                indexArr = walaBets.findIndex((el) => el.id === id)
                if (indexArr === -1) {
                    setWalaBets([...walaBets, payload])
                } else {
                    walaBets[indexArr] = payload
                }
                break
            default:
                return
        }
    }

    const handleTotalBet = (playerID, index) => {
        let previous = previousBet[playerID+'_'+index] === undefined ? 0 : previousBet[playerID+'_'+index]
        let current = currentBet[playerID+'_'+index] === undefined ? 0 : currentBet[playerID+'_'+index]
        return Math.floor(current + previous).toFixed(2)

    }

    // saving previous bet
    const totalMultipleBet = () => {
        console.log('CLONE BETS')
        setPreviousBet({...currentBet})
        
    }
    
    // for plotting index key for dynamic field
    const handleFindIndex = (i) => {
        let indexArr

        switch(selecTedBetType){
            case 'MERON_SELECTED':
                 indexArr = meronFilterPlayer?.findIndex((el) => el.indexKey == i)
                return indexArr
            case 'WALA_SELECTED':
                indexArr = walaFilterPlayer?.findIndex((el) => el.indexKey == i)
                return indexArr
            case 'DRAW_SELECTED':
                indexArr = drawFilterPlayer?.findIndex((el) => el.indexKey == i)
                return indexArr
            default:
                return
        }
    }

    
    const balanceChecker = (val) => {
        if (walletBalanceUpdate.balance !== undefined && walletBalanceUpdate.balance < val) {
            return true
        } else if (playdata.balance < val) {
            return true
        } else {
            return false
        }
    }

    // this will execute after success bet
    const clearPlayerBet = (data) => {
        // clearing of array
        console.log('__FROM CHILD', data)
        if (data) {
            setMeronBets([])
            setDrawBets([])
            setWalaBets([])
            setDisableMeronRemove(true)
            setDisableDrawRemove(true)
            setDisableWalaRemove(true)
            document.querySelector('input[type=radio]:checked').checked = false
            document.querySelector('input[type=number]').value = ''
            setCustomAmount({})
        }
        
    }

    const handleRemovePlayer = (indexKey, betType) => {
        setIndex(indexKey)
        Swal2.fire({
            title: 'Are you sure you want to remove the player?',
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'YES REMOVE',
            customClass: {
                confirmButton: 'btn swal-btn-confirm btn-block',
                cancelButton: 'btn swal-btn-cancel'
            },
            buttonsStyling: false
        }).then((result) => {
            if(result.isConfirmed) {
                // remove player section from array
                dispatch(removePlayer())
                // remove player to dropdown filter
                if (betType === 'meron') {
                    let updateMeronFilter = meronFilterPlayer.filter((el) => {
                        return el.indexKey != indexKey
                    })

                    setMeronFilterPlayer(updateMeronFilter)
                } else if (betType === 'wala') {
                    let updateWalaFilter = walaFilterPlayer.filter((el) => {
                        return el.indexKey != indexKey
                    })

                    setWalaFilterPlayer(updateWalaFilter)
                } else {
                    let updateDrawFilter = drawFilterPlayer.filter((el) => {
                        return el.indexKey != indexKey
                    })

                    setWalaFilterPlayer(updateDrawFilter)
                }
            }
        })
    }

    const handleAddSection = () => {
        if (playerList.length !== playerSection.length) {
            if (playerSection.length >= 50) {
                return
            } else {
                setPlayerSection(() => [...playerSection, playerSection[playerSection.length - 1] + 1])
            }
            
        } else {
            return
        }
        
    }

    const handleToast = () => {
        toast.error(`You have successfully deleted a player.`,{
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: 'colored',
        })
    }
    

    useEffect(() => {
        // add/remove player section
        if (playerInteraction.data === 'ADD_PLAYER') {
            handleAddSection()
            
        } else if (playerInteraction.data === 'REMOVE_PLAYER') {
            setIsLoading(true)
            if (playerSection) {
                
                for (var i = 0; i < playerSection.length; i++) {
                    if(playerSection[i] == index) {
                        playerSection.splice(index, 1)
                    }
                }

                setPlayerSection(playerSection)
                setIsLoading(false)
                handleToast()
                document.querySelector(`select[name=select_${index}]`).value = 'SELECT PLAYER'
            }
        }

        
    }, [playerInteraction])

    useEffect(() => {
        if (playerList.length < 4) {
            localStorage.setItem('playerSection', JSON.stringify(playerList))
        } else {
            localStorage.setItem('playerSection', JSON.stringify(playerSection))
        }
        
    }, [playerSection])

    const sectionMap = playerList.length < 4 ? playerList : playerSection
    const cacheSection = localStorage.getItem('playerSection') != undefined ? JSON.parse(localStorage.getItem('playerSection')) : null
    return (
        <React.Fragment>
            <div className='bet-fields-container'>
                <div className={`bet-fields-tab meron-tab ${selecTedBetType === 'MERON_SELECTED' ? 'active': ''}`}>
                    {   
                        playerList.length <= 0 ?
                            <span>You don't have registered players.</span>
                        :
                        sectionMap.map((p,i) => {
                            return (
                                <div key={i} data-key={i}>
                                    <div className='bet-fields-header'>
                                            <div className='bet-fields-dropdown'>
                                                <select name={`select_${i}`} className={`bet-fields-player-select ${disabledButton ? 'disabled' : ''}`}
                                                    defaultValue={selectDefaultVal}
                                                    onChange={(e) => handleSelectPlayer(e)}
                                                    disabled={meronFilterPlayer.some(n => n.indexKey == i) && disableMeronRemove ? 'disabled' : disabledButton}>
                                                    <option defaultValue={'SELECT PLAYER'} disabled hidden> SELECT PLAYER</option>
                                                    {
                                                        playerList.map((p,x) => {
                                                            return (
                                                                <option key={x}
                                                                    value={`${p.name}_${p.agentPlayerId}_${i}`}
                                                                    disabled={meronFilterPlayer?.some(n => n.playerId === p.agentPlayerId)}
                                                                    >
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                                <div className={`bet-fields-remove-container ${ meronFilterPlayer.some(n => n.indexKey == i) && disableMeronDenomination === false ? 'active' : ''}`}>
                                                    <a href="" className={`bet-fields-btn-remove ${meronFilterPlayer.some(n => n.indexKey == i) && disableMeronRemove ? 'disabled' : ''} `} onClick={() => handleRemovePlayer(i, 'meron')}>
                                                        <FaTrash />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='bet-fields-player-bet'>
                                                <p>TOTAL BET: 
                                                    <span className='bet-fields-player-bet-value'> { currentBet[i] == undefined ? '0' : currentBet[i]} </span> 
                                                    = <span className='bet-fields-player-multiple-bet-value'>{ handleTotalBet(meronFilterPlayer[handleFindIndex(i)]?.playerId, i) }</span>
                                                </p>
                                            </div>
                                    </div>
                                    <div className='bet-fields-custom-bet-container'>
                                        <span className={`bet-fields-cutom-bet-label ${disabledButton ? 'disabled' : ''}`}>Amount:</span>
                                        <input type='number' maxLength={6} placeholder='ENTER AMOUNT'
                                            id={`${meronFilterPlayer[handleFindIndex(i)]?.playerId}_${i}`}
                                            name={`meron-custom-amount_${meronFilterPlayer[handleFindIndex(i)]?.playerId}`} 
                                            className={`bet-fields-custom-bet-input ${disabledButton ? 'disabled' : ''}`}
                                            value={Object.keys(customAmount).length <= 0 ? '' : customAmount[`meron-custom-amount_${meronFilterPlayer[handleFindIndex(i)]?.playerId}`]}
                                            disabled={meronFilterPlayer.some(n => n.indexKey == i) && disableMeronDenomination === false ? '' : disabledButton ? disabledButton : 'disabled'}
                                            onChange={(e) => handleInput(e)}/>
                                    </div>

                                    <div className='bet-fields-body'>
                                        <div className={`form-group grid-btn ${ meronFilterPlayer.some(n => n.indexKey == i) && disableMeronDenomination === false ? '' : 'disabled'}`}>
                                            {
                                                betFields.map((denomination,b) => {
                                                    return (
                                                        <div className='button' key={b}>
                                                            <input type='radio' id={`radio-meron-${meronFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`} 
                                                                name={`rd-meron-${meronFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}`} 
                                                                value={denomination.value}
                                                                // checked={rdState === item.first_name+'-'+btn}
                                                                onChange={() => handleRadioButton(meronFilterPlayer[handleFindIndex(i)]?.playerId, denomination.value, i)}
                                                                />
                                                            <label className={`btn btn-outline-light btn-bet btn-meron
                                                                ${disabledButton ? 'disabled' : balanceChecker(denomination.value) ? 'disabled' : ''}`} 
                                                                htmlFor={`radio-meron-${meronFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`}>{denomination.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>

                <div className={`bet-fields-tab draw-tab ${selecTedBetType === 'DRAW_SELECTED' ? 'active': ''}`}>
                    {
                        playerList.length <= 0 ?
                            <span>You don't have registered players.</span>
                        :
                        sectionMap.map((p,i) => {
                            return (
                                <div key={i} data-key={i}>
                                    <div className='bet-fields-header'>
                                            <div className='bet-fields-dropdown'>
                                                <select className={`bet-fields-player-select ${disabledButton ? 'disabled' : ''}`}
                                                    defaultValue={'SELECT PLAYER'}
                                                    onChange={(e) => handleSelectPlayer(e)}
                                                    disabled={drawFilterPlayer.some(n => n.indexKey == i) && disableDrawRemove ? 'disabled' : disabledButton}>
                                                    <option defaultValue={'SELECT PLAYER'} disabled hidden> SELECT PLAYER</option>
                                                    {
                                                        playerList.map((p,x) => {
                                                            return (
                                                                <option key={x}
                                                                    value={`${p.name}_${p.agentPlayerId}_${i}`}
                                                                    disabled={drawFilterPlayer?.some(n => n.playerName === p.name)}
                                                                    >
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                                <div className={`bet-fields-remove-container ${ drawFilterPlayer.some(n => n.indexKey == i) && disableDrawDenomination === false ? 'active' : ''}`}>
                                                    <a href="#" className={`bet-fields-btn-remove ${drawFilterPlayer.some(n => n.indexKey == i) && disableDrawRemove ? 'disabled' : ''}`} onClick={() => handleRemovePlayer(i, 'draw')}>
                                                        <FaTrash />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='bet-fields-player-bet'>
                                                <p>TOTAL BET: 
                                                    <span className='bet-fields-player-bet-value'> { currentBet[i] == undefined ? '0' : currentBet[i]} </span> 
                                                    = <span className='bet-fields-player-multiple-bet-value'>{ handleTotalBet(i) }</span>
                                                </p>
                                            </div>
                                    </div>
                                    <div className='bet-fields-custom-bet-container'>
                                        <span className={`bet-fields-cutom-bet-label ${disabledButton ? 'disabled' : ''}`}>Amount:</span>
                                        <input type='number' maxLength={6} placeholder='ENTER AMOUNT'
                                            id={`${drawFilterPlayer[handleFindIndex(i)]?.playerId}_${i}`}
                                            name={`draw-custom-amount_${drawFilterPlayer[handleFindIndex(i)]?.playerId}`}
                                            className={`bet-fields-custom-bet-input ${disabledButton ? 'disabled' : ''}`}
                                            value={customAmount[`draw-custom-amount_${drawFilterPlayer[handleFindIndex(i)]?.playerId}`] <= 0 ? '' : customAmount[`draw-custom-amount_${drawFilterPlayer[handleFindIndex(i)]?.playerId}`]}
                                            disabled={drawFilterPlayer.some(n => n.indexKey == i) && disableDrawDenomination === false ? '' : disabledButton ? disabledButton : 'disabled'}
                                            onInput={(e) => handleInput(e)}/>
                                    </div>

                                    <div className='bet-fields-body'>
                                        <div className={`form-group grid-btn ${ drawFilterPlayer.some(n => n.indexKey == i) && disableDrawDenomination === false ? '' : 'disabled'}`}>
                                            {
                                                betFields.map((denomination,b) => {
                                                    return (
                                                        <div className='button' key={b}>
                                                            <input type='radio' id={`radio-draw-${drawFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`} 
                                                                name={`rd-draw-${drawFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}`} 
                                                                value={denomination.value}
                                                                // checked={rdState === item.first_name+'-'+btn}
                                                                onChange={() => handleRadioButton(drawFilterPlayer[handleFindIndex(i)]?.playerId, denomination.value, i)}
                                                                />
                                                            <label className={`btn btn-outline-light btn-bet btn-draw
                                                                ${disabledButton ? 'disabled' : balanceChecker(denomination.value) ? 'disabled' : ''}`} 
                                                                htmlFor={`radio-draw-${drawFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`}>{denomination.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={`bet-fields-tab wala-tab ${selecTedBetType === 'WALA_SELECTED' ? 'active': ''}`}>
                    {
                        playerList.length <= 0 ?
                            <span>You don't have registered players.</span>
                        :
                        sectionMap.map((p,i) => {
                            return (
                                <div key={i} data-key={i}>
                                    <div className='bet-fields-header'>
                                            <div className='bet-fields-dropdown'>
                                                <select className={`bet-fields-player-select ${disabledButton ? 'disabled' : ''}`}
                                                    defaultValue={'SELECT PLAYER'}
                                                    onChange={(e) => handleSelectPlayer(e)}
                                                    disabled={walaFilterPlayer.some(n => n.indexKey == i) && disableWalaRemove ? 'disabled' : disabledButton}>
                                                    <option defaultValue={'SELECT PLAYER'} disabled hidden> SELECT PLAYER</option>
                                                    {
                                                        playerList.map((p,x) => {
                                                            return (
                                                                <option key={x}
                                                                    value={`${p.name}_${p.agentPlayerId}_${i}`}
                                                                    disabled={walaFilterPlayer?.some(n => n.playerName === p.name)}
                                                                    >
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                                <div className={`bet-fields-remove-container ${ walaFilterPlayer.some(n => n.indexKey == i) && disableWalaDenomination === false ? 'active' : ''}`}>
                                                    <a href="#" className={`bet-fields-btn-remove ${walaFilterPlayer.some(n => n.indexKey == i) && disableWalaRemove ? 'disabled' : ''}`} onClick={() => handleRemovePlayer(i, 'wala')}>
                                                        <FaTrash />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='bet-fields-player-bet'>
                                                <p>TOTAL BET: 
                                                    <span className='bet-fields-player-bet-value'> { currentBet[i] == undefined ? '0' : currentBet[i]} </span> 
                                                    = <span className='bet-fields-player-multiple-bet-value'>{ handleTotalBet(i) }</span>
                                                </p>
                                            </div>
                                    </div>
                                    <div className='bet-fields-custom-bet-container'>
                                        <span className={`bet-fields-cutom-bet-label ${disabledButton ? 'disabled' : ''}`}>Amount:</span>
                                        <input type='number' maxLength={6} placeholder='ENTER AMOUNT'
                                            id={`${walaFilterPlayer[handleFindIndex(i)]?.playerId}_${i}`}
                                            name={`wala-custom-amount_${walaFilterPlayer[handleFindIndex(i)]?.playerId}`}
                                            className={`bet-fields-custom-bet-input ${disabledButton ? 'disabled' : ''}`}
                                            value={customAmount[`wala-custom-amount_${walaFilterPlayer[handleFindIndex(i)]?.playerId}`] <= 0 ? '' : customAmount[`wala-custom-amount_${walaFilterPlayer[handleFindIndex(i)]?.playerId}`]}
                                            disabled={walaFilterPlayer.some(n => n.indexKey == i) && disableWalaDenomination === false ? '' : disabledButton ? disabledButton : 'disabled'}
                                            onInput={(e) => handleInput(e)}/>
                                    </div>

                                    <div className='bet-fields-body'>
                                        <div className={`form-group grid-btn ${ walaFilterPlayer.some(n => n.indexKey == i) && disableWalaDenomination === false ? '' : 'disabled'}`}>
                                            {
                                                betFields.map((denomination,b) => {
                                                    return (
                                                        <div className='button' key={b}>
                                                            <input type='radio' id={`radio-wala-${walaFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`} 
                                                                name={`rd-wala-${walaFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}`} 
                                                                value={denomination.value}
                                                                // checked={rdState === item.first_name+'-'+btn}
                                                                onChange={() => handleRadioButton(walaFilterPlayer[handleFindIndex(i)]?.playerId, denomination.value, i)}
                                                                />
                                                            <label className={`btn btn-outline-light btn-bet btn-wala
                                                                ${disabledButton ? 'disabled' : balanceChecker(denomination.value) ? 'disabled' : ''}`} 
                                                                htmlFor={`radio-wala-${walaFilterPlayer[handleFindIndex(i)]?.playerName.replace(/\s/g, '')}-${b}`}>{denomination.label}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <PlaceBetButton placeBet={selecTedBetType === 'MERON_SELECTED' ? meronBets : selecTedBetType === 'WALA_SELECTED' ? walaBets : drawBets} 
                customAmount={customAmount} 
                disableBet={disableBet} 
                totalBetFunc={totalMultipleBet} 
                tempFunc={clearPlayerBet} 
                walletSubscription={props.walletSubscription}
            />
        </React.Fragment>
    )
}