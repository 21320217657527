import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import AddPlayerButton from './AddPlayerButton'


function EditFormModal(props) {

    const  [name,setName] = useState(props.player.name)
    const  [number,setNumber] = useState(props.player.mobileNumber)
    
    const  [formErrors, setFormErrors] = useState({})
    
    let values ={
        id:props.player.id,
        name:name,
        mobileNumber:number
    }

    const handleClick = (data) => {
        setFormErrors(data)
    }   
    
    const handleSave = () => {
        alert(values.name)
    }
    return (
      
        <Form>
        <Form.Group>
            <Form.Control
                className='formInput'
                type='text'
                placeholder='Name *'
                name='name'
                value={name}
                onChange={event => setName(event.target.value)}
                required
            />
        </Form.Group>
        <p>{formErrors.name}</p>
        <Form.Group>
            <Form.Control
                className='formInput'
                type='tel'
                placeholder='Phone *'
                name='phone'
                value={number}
                onChange={event => setNumber(event.target.value)}
            />
        </Form.Group>
        <p>{formErrors.mobileNumber}</p>
        <AddPlayerButton values={values} parentCallback = {handleClick}/>
    </Form>


    );
}

export default EditFormModal;